import type { NextPage } from 'next';
import { useState, useEffect } from 'react';
import {
  Box, Autocomplete, TextField, AutocompleteRenderInputParams, AutocompleteChangeReason,
  Popper, ClickAwayListener, Checkbox, IconButton, InputAdornment, FormControlLabel
} from '@mui/material';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useDebouncedCallback } from 'use-debounce';
import { ResultSet, Query } from '@cubejs-client/core';
import LoadingFilterError from '../loadingFilterError/loadingFilterError';
import EmptyFilter from '../emptyFilter/emptyFilter';
import SearchFilterIndicator from '../searchFilterIndicator/searchFilterIndicator';
import FilterChips from '../filterChips/filterChips';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';


interface Props {
  options: any[];
  optionsCopy: any[];
  setOptions: (value: any[]) => void;
  filter: Filter;
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filterItem: AudienceFilter;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
  clearFilters: boolean;
  table: string;
  audienceFilters: AudienceFilter[];
  loading: boolean;
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
}

const SearchMultiSelectFilter: NextPage<Props> = ({
  options, filter, anchorEl, handleClose, optionsCopy, setOptions, filterItem, setFilterGroup, filterGroup, clearFilters, table,
  audienceFilters, loading, setAudienceFilters, setFilterChanged,
}) => {
  const {
    handleAddFilter, handleRemoveAudienceFilter, handleRemoveFilterValue, modifyFilters, modifyJobTitleFilter
  } = useBasicFiltersState();
  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [filterSearched, setFilterSearched] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const [searching, setSearching] = useState<boolean>(false);
  const [chipsWrapperWidth, setChipsWrapperWidth] = useState<number>(0);
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (filter.title && anchorEl) {
      const width = filter.title.length * 15 < 300 ? 300 : filter.title.length * 15;
      setChipsWrapperWidth(width);
    }
  }, [filter.title, anchorEl]);

  useEffect(() => {
    setFilterOptions(options);
  }, [options]);

  useEffect(() => {
    if (filterItem.values.length) {
      setFilterValue([...filterItem.values.map((value: string) => ({ [filter.value]: value }))]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItem]);

  useEffect(() => {
    if (clearFilters) {
      setFilterValue([]);
    }
  }, [clearFilters]);

  useEffect(() => {
    setSearching(loading);
  }, [loading]);


  const handleChangeFilterValue = (items: any, reason: AutocompleteChangeReason) => {
    if (filter) {
      switch (reason) {
        case 'createOption':
          break;
        case 'selectOption':
          filterItem.values = items.length ? [ ...items.map((value: any) => value[filter.value]) ] : [];
          setFilterGroup([...filterGroup]);
          const filterToAdd: AudienceFilter = {
            member: filter.value,
            operator: 'equals',
            values: [ ...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
          setSearchValue('');
          setFilterValue(items);
          setFilterOptions([...optionsCopy]);
          setFilterSearched(false);
          break;
        case 'removeOption':
          const previousValues = filterItem.values;
          setSearchValue('');
          if (!items.length) {
            filterItem.values = [];
            setFilterValue([]);
            setFilterGroup([...filterGroup]);
            handleRemoveAudienceFilter(
              { member: filter.value, operator: 'equals', values: previousValues }, audienceFilters, setAudienceFilters
            );
          } else {
            const newValues = [...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])];
            filterItem.values = [...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])];
            setFilterGroup([...filterGroup]);
            setFilterValue(items);
            handleRemoveFilterValue(
              { member: filter.value, operator: 'equals', values: previousValues }, newValues, audienceFilters, setAudienceFilters
            );
          }
          setFilterOptions([...optionsCopy]);
          break;
        case 'clear':
          const prevValues = filterItem.values;
          if (prevValues.length) {
            filterItem.values = [];
            setFilterGroup([...filterGroup]);
            setSearchValue('');
            setFilterValue([]);
            handleRemoveAudienceFilter(
              { member: filter.value, operator: 'equals', values: prevValues }, audienceFilters, setAudienceFilters
            );
            setFilterSearched(false);
          }
          setFilterOptions([...optionsCopy]);
          break;
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const handleFilterOptions = async (search: string) => {
    if (search.length > 0) {
      try {
        setSearchError(false);
        setSearching(true);
        const columnName = filter.value.split('.')[1];
        const modifiedFilters = modifyJobTitleFilter(modifyFilters(audienceFilters)) as AudienceFilter[];
        const query: Query = {
          measures: columnName !== 'caregiver_states_org' ? [
            filter.searchColumn ? `${filter.searchColumn.split('.')[0]}.count` : `${filter.value.split('.')[0]}.count`
          ] : [],
          dimensions: [ filter.searchColumn ? filter.searchColumn : filter.value ],
          filters: modifiedFilters.length > 0 ? [
            ...modifiedFilters.filter((item: AudienceFilter) => {
              const splitValue = item.member && item.member.split('.')[1];
              const forwardedFilterSplitValue = filter.value.split('.')[1];
              return splitValue && splitValue !== forwardedFilterSplitValue;;
            }),
            {
              member: filter.searchColumn ?
                filter.searchColumn : filter.value.split('.')[1] !== 'name' ? filter.value : `${filter.value.split('.')[0]}.company_name_org`,
              operator: 'contains',
              values: [ search ],
            }
          ] : [
            {
              member: filter.searchColumn ?
                filter.searchColumn : filter.value.split('.')[1] !== 'name' ? filter.value : `${filter.value.split('.')[0]}.company_name_org`,
              operator: 'contains',
              values: [ search ],
            }
          ],
          limit: 1000,
        };
        const resultSet: ResultSet<any> = await cubejsApiWrapper(query, { mutexKey: filter.value.split('.')[1] });
        setOptions(
          resultSet.tablePivot().map((item: any) => ({ [filter.value as string]: item[filter.searchColumn ? filter.searchColumn : filter.value] }))
        );
        setFilterSearched(true);
      } catch (error) {
        setOptions([]);
        setSearchError(true);
        setFilterSearched(true);
      } finally {
        setSearching(false);
      }
    } else {
      setSearchError(false);
      setOptions(optionsCopy);
      setFilterSearched(false);
      setSearching(false);
    }
  };

  const debouncedSearch = useDebouncedCallback(handleFilterOptions, 700);

  const getFilterLabel = (option: any): string => {
    if (filter.columnType !== 'url') {
      if (typeof option === 'string') {
        return option;
      } else {
        return option[filter.value] ? option[filter.value] : 'NULL';
      }
    } else {
      if (typeof option === 'string') {
        return option.split('-*-')[0];
      } else {
        return option[filter.value] ? option[filter.value].split('-*-')[0] : 'NULL';
      }
    }
  };


  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='search-multi-select-popover'>
          <Box>
            <FilterChips
              minWidth={300}
              width={chipsWrapperWidth}
              filterValue={filterValue}
              filterOptions={!!filterOptions.length}
              filter={filter}
              getLabel={getFilterLabel}
              handleChangeFilterValue={handleChangeFilterValue}
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
            />
            <Autocomplete
              disablePortal
              multiple
              freeSolo
              fullWidth
              disableCloseOnSelect
              open={isDropdownOpen}
              value={filterValue}
              options={
                searching ? [{}] :
                  searchError ? [{}] :
                    filterSearched && !filterOptions.length ? [{}] :
                      filterOptions
              }
              id={`filter-${filter.value}-${Math.random()}`}
              size='small'
              inputValue={searchValue}
              getOptionLabel={(item: any) => item[filter.value] ? `${item[filter.value]}` : ''}
              renderOption={(props: any, item: any, { selected }) => {
                if (searching) {
                  return <SearchFilterIndicator size='regular' />;
                }
                if (searchError) {
                  return <LoadingFilterError />;
                }
                if (filterSearched && !filterOptions.length) {
                  return <EmptyFilter />;
                }
                return (
                  <Box
                    className='u-flex u-flex-align-center filter-checkbox-wrapper'
                    key={item[filter.value]}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          { ...props}
                          checked={selected}
                          classes={{
                            root: 'checkbox-root'
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={filter.columnType !== 'url' ? `${item[filter.value] ? item[filter.value] : 'NULL'}` :
                        `${item[filter.value] ? item[filter.value].split('-*-')[0] : 'NULL'}`}
                      name={item[filter.value] || ''}
                      value={item[filter.value]}
                    />
                  </Box>
                );
              }}
              classes={{
                inputRoot: !!anchorEl && !!filterOptions.length ? 'multi-select-input' : 'multi-select-input no-bottom-radius',
                listbox: 'multi-select-paper'
              }}
              filterOptions={(item: any) => item}
              isOptionEqualToValue={(option: Filter, value: string) => option[filter.value] === value[filter.value]}
              onChange={(event: React.ChangeEvent<{}>, items: any, reason: AutocompleteChangeReason) => handleChangeFilterValue(items, reason)}
              renderTags={() => null}
              renderInput={(params: AutocompleteRenderInputParams) => (
                isDropdownOpen ? (
                  <TextField
                    {...params}
                    color='primary'
                    placeholder={!filterValue.length ? 'Search' : ''}
                    fullWidth
                    className='full-width filter-text-input'
                    size='small'
                    value={searchValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchValue(event.target.value);
                      debouncedSearch(event.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: '48px',
                      },
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => {
                            setSearchValue('');
                            debouncedSearch('');
                          }}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                ) : null
              )}
            />
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default SearchMultiSelectFilter;

import type { NextPage } from 'next';
import { useState, useEffect } from 'react';
import {
  Box, Autocomplete, TextField, Popper, AutocompleteRenderInputParams, AutocompleteChangeReason,
  ClickAwayListener, Checkbox, FormControlLabel,
} from '@mui/material';
import { AudienceFilter, Filter, RelatedAudienceFilter, CombinedAudienceFilter } from '../../types/Cube.interfaces';
import { useUiState } from '../../context/uiState';
import FilterChips from '../filterChips/filterChips';


interface Props {
  filter: Filter;
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filterItem: AudienceFilter;
  numericalFilterValues: string[];
  setNumericalFilterValues: (value: string[]) => void;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
  clearFilters: boolean;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
  handleCombinedNumericFilter: (
    filterName: string, currentFilter: CombinedAudienceFilter, currentFilters: AudienceFilter[] | CombinedAudienceFilter[],
    setFilters: (value: any[]) => void
  ) => void;
}

const NumericalSelectFilter: NextPage<Props> = ({
  filter, anchorEl, handleClose, filterItem, setFilterGroup, filterGroup, clearFilters, setFilterChanged,
  audienceFilters, setAudienceFilters, numericalFilterValues, setNumericalFilterValues, handleCombinedNumericFilter
}) => {
  const { displayToast } = useUiState();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [filterOptions] = useState<{ label: string, value: string }[]>([
    { label: '1 Star', value: '1' },
    { label: '2 Star', value: '2' },
    { label: '3 Star', value: '3' },
    { label: '4 Star', value: '4' },
    { label: '5 Star', value: '5' },
    { label: '0 - Not Reviewed', value: '0'}
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const [chipsWrapperWidth, setChipsWrapperWidth] = useState<number>(0);

  useEffect(() => {
    if (filter.title && anchorEl) {
      const width = filter.title.length * 15 < 300 ? 300 : filter.title.length * 15;
      setChipsWrapperWidth(width);
    }
  }, [filter.title, anchorEl]);

  useEffect(() => {
    if (numericalFilterValues.length) {
      setFilterValue([...numericalFilterValues.map(item => ({ label: +item === 0 ? item + ' - Not Reviewed' : item +' Star', value: item }))]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numericalFilterValues]);

  useEffect(() => {
    if (clearFilters) {
      setFilterValue([]);
      setNumericalFilterValues([]);
      filterItem.values = [];
      setFilterGroup([...filterGroup]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters]);

  useEffect(() => {
    if (audienceFilters.length && !numericalFilterValues.length) {
      const filterIndex = audienceFilters.findIndex((el: any) => {
        return !!el.or && el.or.length &&
        (
          (el.or[0].member && el.or[0].member.split('.')[1] === filter.value.split('.')[1]) ||
          (el.or[0].and && el.or[0].and.length && el.or[0].and[0].member && el.or[0].and[0].member.split('.')[1] === filter.value.split('.')[1])
        );
      });
      if (filterIndex > -1) {
        setFilterValue([audienceFilters[filterIndex]]);
        const allFilterValues: string[][] = [];
        (audienceFilters[filterIndex] as any).or.forEach((item: any) => {
          const arrayValues: string[] = [];
          item.and.forEach((value: any) => {
            value.values.forEach((element: string) => {
              arrayValues.push(element);
            });
          });
          allFilterValues.push(arrayValues);
        });
        const foundFilterValues: { label: string, value: string }[] = [];
        allFilterValues.forEach((item: string[]) => {
          const minValue = Math.min(...item.map((value: string) => +value));
          const findValue = filterOptions.find((item: { label: string, value: string }) => +item.value === minValue);
          if (findValue) {
            foundFilterValues.push(findValue);
          }
        });
        setFilterValue(foundFilterValues);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numericalFilterValues, audienceFilters]);

  useEffect(() => {
    if (clearFilters) {
      setFilterValue([]);
    }
  }, [clearFilters]);

  const makeCombinedFilter = (filterArr: any[]): AudienceFilter[] | RelatedAudienceFilter[] => {
    filterItem.values.forEach(filterValue => {
      if (+filterValue === 5 || +filterValue === 0) {
        const filterToAdd = {
          and: [
            {
              member: filter.value,
              operator: 'equals',
              values: [filterValue]
            }
          ]
        };
        filterArr.push(filterToAdd);
      } else if (+filterValue < 5 && +filterValue >= 1) {
        const filterToAdd = {
          and: [
            {
              member: filter.value,
              operator: 'gte',
              values: [filterValue],
            },
            {
              member: filter.value,
              operator: 'lt',
              values: [`${+filterValue + 1}`],
            }
          ],
        };
        filterArr.push(filterToAdd);
      }
    });
    return filterArr;
  };

  const handleChangeFilterValue = async (items: any, reason: AutocompleteChangeReason) => {
    if (filter) {
      switch (reason) {
        case 'createOption':
          break;
        case 'selectOption':
          filterItem.values = items.length ? [ ...items.map((item: any) => item.value ? item.value: null) ] : [];
          setFilterGroup([...filterGroup]);
          const filtersToAdd: AudienceFilter[] | RelatedAudienceFilter[] = makeCombinedFilter([]);
          handleCombinedNumericFilter(
            filter.value, { or: filtersToAdd }, audienceFilters, setAudienceFilters
          );
          setSearchValue('');
          setFilterValue(items);
          break;
        case 'removeOption':
          setSearchValue('');
          if (!items.length) {
            filterItem.values = [];
            setFilterValue([]);
            setFilterGroup([...filterGroup]);
            handleCombinedNumericFilter(filter.value, { or: [] }, audienceFilters, setAudienceFilters);
          } else {
            filterItem.values = [...items.map((item: any) => item.value ? item.value : null)];
            setFilterGroup([...filterGroup]);
            setFilterValue(items);
            const filtersToAdd: AudienceFilter[] | RelatedAudienceFilter[] = makeCombinedFilter([]);
            handleCombinedNumericFilter(
              filter.value, { or: filtersToAdd }, audienceFilters, setAudienceFilters
            );
          }
          break;
        case 'clear':
          filterItem.values = [];
          setFilterValue([]);
          setFilterGroup([...filterGroup]);
          handleCombinedNumericFilter(
            filter.value, { or: [] }, audienceFilters, setAudienceFilters
          );
          break;
        default:
          displayToast('Option not valid!', 'warning');
          break;
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const getLabel = (option: any): string => typeof option === 'string' ? option : option.label;


  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='multi-select-popover'>
          <Box>
            <FilterChips
              minWidth={240}
              width={chipsWrapperWidth}
              filterValue={filterValue}
              filterOptions={!!filterOptions.length}
              filter={filter}
              getLabel={getLabel}
              handleChangeFilterValue={handleChangeFilterValue}
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
            />
            <Autocomplete
              disablePortal
              multiple
              freeSolo
              fullWidth
              disableCloseOnSelect
              open={isDropdownOpen}
              value={filterValue}
              options={filterOptions}
              id={`filter-${filter.value}-${Math.random()}`}
              size='small'
              inputValue={searchValue}
              getOptionLabel={(item: any) => item.label}
              renderOption={(props: any, item: any, { selected }) => (
                <Box
                  className='u-flex u-flex-align-center filter-checkbox-wrapper'
                  key={Math.random()}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...props}
                        checked={selected}
                        classes={{
                          root: 'checkbox-root'
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={`${item.label}`}
                    name={item[filter.value] || ''}
                    value={item[filter.value]}
                  />
                </Box>
              )}
              classes={{
                inputRoot: !!anchorEl && !!filterOptions.length ? 'multi-select-input' : 'multi-select-input no-bottom-radius',
                listbox: 'multi-select-paper',
              }}
              filterOptions={(item: any) => item}
              isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
              onChange={(event: React.ChangeEvent<{}>, items: any, reason: AutocompleteChangeReason) => handleChangeFilterValue(items, reason)}
              renderTags={() => null}
              renderInput={(params: AutocompleteRenderInputParams) => (
                isDropdownOpen ? (
                  <TextField
                    {...params}
                    sx={{ visibility: 'hidden', height: '0px' }}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        visibility: 'hidden',
                        height: '0px',
                        padding: '0px',
                      }
                    }}
                  />
                ) : null
              )}
            />
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default NumericalSelectFilter;

import type { NextPage } from 'next';
import {
  Box, ClickAwayListener, Popper, Autocomplete, Select, AutocompleteChangeReason, AutocompleteRenderInputParams,
  InputBase, ListItemText, MenuItem, SelectChangeEvent, TextField, Checkbox, IconButton, InputAdornment, FormControlLabel
} from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useState, useEffect } from 'react';
import { BinaryOperator } from '@cubejs-client/core';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { useDebouncedCallback } from 'use-debounce';
import { ResultSet, Query } from '@cubejs-client/core';
import LoadingFilterError from '../loadingFilterError/loadingFilterError';
import EmptyFilter from '../emptyFilter/emptyFilter';
import SearchFilterIndicator from '../searchFilterIndicator/searchFilterIndicator';
import FilterChips from '../filterChips/filterChips';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';

interface Props {
  options: any[];
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filter: Filter;
  filterItem: AudienceFilter;
  filterGroup: AudienceFilter[];
  setFilterGroup: (value: AudienceFilter[]) => void;
  table: string;
  clearFilters: boolean;
  audienceFilters: AudienceFilter[];
  loading: boolean;
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
}

const SearchMultiSelectPrefixTechnologyFilter: NextPage<Props> = ({
  options, anchorEl, handleClose, filter , filterItem, filterGroup, setFilterGroup, table, clearFilters,
  audienceFilters, loading, setAudienceFilters, setFilterChanged,
}) => {
  const {
    modifyFilters, handleRemoveFilterValue, handleAddFilter, handleRemoveAudienceFilter, modifyJobTitleFilter
  } = useBasicFiltersState();
  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [relationValue, setRelationValue] = useState<BinaryOperator>('contains');
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [relations] = useState<{ title: string, value: BinaryOperator }[]>([
    { title: 'Is', value: 'contains' },
    { title: 'Is not', value: 'notContains' },
    { title: 'Starts with', value: 'startsWith' },
  ]);
  const [open, setOpen] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [filterSearched, setFilterSearched] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const [searching, setSearching] = useState<boolean>(false);
  const [chipsWrapperWidth, setChipsWrapperWidth] = useState<number>(0);
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (filter.title && anchorEl) {
      const width = filter.title.length * 15 < 300 ? 300 : filter.title.length * 15;
      setChipsWrapperWidth(width);
    }
  }, [filter.title, anchorEl]);

  useEffect(() => {
    if (filterItem) {
      setRelationValue(filterItem.operator !== 'equals' ? filterItem.operator : 'contains');
    }
  }, [filterItem]);

  useEffect(() => {
    setFilterOptions(options.filter((item: { [key: string]: string }) => item[filter.value]));
  }, [options, filter]);

  useEffect(() => {
    if (filterItem.values.length) {
      setFilterValue([...filterItem.values.map((value: string) => ({ [filter.value]: value }))]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItem]);

  useEffect(() => {
    if (clearFilters) {
      setFilterValue([]);
    }
  }, [clearFilters]);

  useEffect(() => {
    if (filterItem) {
      const values = filterItem.values.map((item: string) => ({ [filterItem.member as string]: item }));
      setFilterValue([ ...values ]);
    }
  }, [filterItem]);

  useEffect(() => {
    setSearching(loading);
  }, [loading]);

  const updateFilterOperator = (operator: BinaryOperator, filterType: string) => {
    setRelationValue(operator);
    const foundFilter = audienceFilters
      .find((item: AudienceFilter) => {
        return item.member === filterType && item.values && item.values.every((value: string) => filterItem.values.includes(value));
      });
    filterItem.operator = operator;
    setFilterGroup([...filterGroup]);
    if (filterValue.length > 0) {
      if (foundFilter) {
        foundFilter.operator = operator;
        setAudienceFilters([...audienceFilters]);
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const handleChangeFilterValue = (items: any, reason: AutocompleteChangeReason) => {
    switch (reason) {
      case 'createOption':
        break;
      case 'selectOption':
        filterItem.values = items.length ? [ ...items.map((value: any) => value[filter.value]) ] : [];
        setFilterGroup([...filterGroup]);
        const filterToAdd = {
          member: filter.value,
          operator: relationValue,
          values: [ ...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])]
        };
        handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        setSearchValue('');
        setFilterValue(items);
        setFilterOptions(options.filter((item: { [key: string]: string }) => item[filter.value]));
        setFilterSearched(false);
        break;
      case 'removeOption':
        const previousValues = filterItem.values;
        setSearchValue('');
        if (!items.length) {
          filterItem.values = [];
          setFilterValue([]);
          setFilterGroup([...filterGroup]);
          handleRemoveAudienceFilter(
            { member: filter.value, operator: relationValue, values: previousValues }, audienceFilters, setAudienceFilters
          );
          setFilterOptions(options.filter((item: { [key: string]: string }) => item[filter.value]));
        } else {
          const newValues = [...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])];
          filterItem.values = [...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])];
          setFilterGroup([...filterGroup]);
          setFilterValue(items);
          setFilterOptions(options.filter((item: { [key: string]: string }) => item[filter.value]));
          handleRemoveFilterValue(
            { member: filter.value, operator: relationValue, values: previousValues }, newValues, audienceFilters, setAudienceFilters
          );
        }
        break;
      case 'clear':
        const prevValues = filterItem.values;
        if (prevValues.length) {
          filterItem.values = [];
          setFilterGroup([...filterGroup]);
          setFilterOptions(options.filter((item: { [key: string]: string }) => item[filter.value]));
          setSearchValue('');
          setFilterValue([]);
          handleRemoveAudienceFilter(
            { member: filter.value, operator: relationValue, values: prevValues }, audienceFilters, setAudienceFilters
          );
          setFilterSearched(false);
        }
        break;
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 100);
  };

  const handleFilterOptions = async (search: string) => {
    if (search.length > 0) {
      try {
        setSearchError(false);
        setSearching(true);
        const modifiedFilters = modifyJobTitleFilter(modifyFilters(audienceFilters)) as AudienceFilter[];
        const query: Query = {
          measures: [
            filter.searchColumn ? `${filter.searchColumn.split('.')[0]}.count` : `${table.toUpperCase()}.count`
          ],
          dimensions: [
            filter.searchColumn ? filter.searchColumn : filter.value
          ],
          filters: modifiedFilters.length > 0 ? [
            ...modifiedFilters.filter((item: AudienceFilter) => {
              const splitValue = item.member && item.member.split('.')[1];
              const forwardedFilterSplitValue = filter.value.split('.')[1];
              return splitValue && splitValue !== forwardedFilterSplitValue;
            }),
            {
              member: filter.searchColumn ? filter.searchColumn : filter.value,
              operator: 'contains',
              values: [ search ],
            }
          ] : [
            {
              member: filter.searchColumn ? filter.searchColumn : filter.value,
              operator: 'contains',
              values: [ search ],
            }
          ],
          limit: 1000,
        };
        const resultSet: ResultSet<any> = await cubejsApiWrapper(query, { mutexKey: filter.value.split('.')[1] });
        const data = resultSet.tablePivot();
        if (
          filter.value.includes('about_us_org') || filter.value.includes('headquarters_location_org') || filter.value.includes('publications_per') ||
          filter.value.includes('website_languages_org')
        ) {
          setFilterOptions([{ [filter.value as string]: searchValue }, ...data]);
        } else {
          if (filter.searchColumn) {
            setFilterOptions([
              { [filter.value as string]: searchValue },
              ...data.map((item: any) => (
                { [filter.value as string]: item[filter.searchColumn ? filter.searchColumn : filter.value] }
              ))
            ]);
          } else {
            setFilterOptions(data);
          }
        }
        setFilterSearched(true);
      } catch (error) {
        setFilterSearched(true);
        setFilterOptions([]);
        setSearchError(true);
      } finally {
        setSearching(false);
      }
    } else {
      setSearchError(false);
      setFilterOptions(options.filter((item: { [key: string]: string }) => item[filter.value]));
      setFilterSearched(false);
      setSearching(false);
    }
  };

  const debouncedSearch = useDebouncedCallback(handleFilterOptions, 700);

  const getFilterLabel = (option: any): string => {
    if (filter.columnType !== 'url') {
      if (typeof option === 'string') {
        return option;
      } else {
        return option[filter.value] ? option[filter.value] : 'NULL';
      }
    } else {
      if (typeof option === 'string') {
        return option.split('-*-')[0];
      } else {
        return option[filter.value].includes('-*-') ? option[filter.value].split('-*-')[0] : option[filter.value];
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='multi-select-prefix'>
          <Box className='multi-select-prefix-wrapper u-flex u-flex-direction-column'>
            <Box className='u-flex'>
              <Box className='prefix-wrapper'>
                <Select
                  labelId='relation-label'
                  id='relation-group'
                  value={relationValue}
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  margin='dense'
                  variant='outlined'
                  onChange={(e: SelectChangeEvent<any>) => {
                    updateFilterOperator(e.target.value, filter.value);
                    if (searchValue.length > 0) {
                      debouncedSearch(searchValue);
                    }
                  }}
                  classes={{ select: 'select' }}
                  input={
                    <InputBase
                      className='select-input'
                      sx={{
                        height: '100%',
                        borderBottomLeftRadius: open ? '0px !important' : '4px !important'
                      }}
                    />
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    classes: {
                      paper: 'select-mui-root'
                    }
                  }}
                >
                  {relations.map((item: { title: string, value: BinaryOperator }, index: number) => (
                    <MenuItem key={index} value={item.value} dense={true} sx={{ padding: '2px 8px !important' }}>
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <FilterChips
                minWidth={300}
                width={chipsWrapperWidth}
                filterValue={filterValue}
                filterOptions={!!filterOptions.length}
                filter={filter}
                getLabel={getFilterLabel}
                handleChangeFilterValue={handleChangeFilterValue}
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
              />
            </Box>
            <Box sx={{ minWidth: '300px', maxWidth: '400px', marginLeft: 'auto', width: chipsWrapperWidth + 'px'  }}>
              <Autocomplete
                disablePortal
                multiple
                freeSolo
                disableCloseOnSelect
                open={isDropdownOpen}
                value={filterValue}
                options={
                  searching ? [{}] :
                    searchError ? [{}] :
                      filterSearched && !filterOptions.length ? [{}] :
                        filterOptions
                }
                id={`filter-${filter.value}-${Math.random()}`}
                size='small'
                inputValue={searchValue}
                getOptionLabel={(item: any) => item[filter.value] ? `${item[filter.value]}` : ''}
                renderOption={(props: any, item: any, { selected }) => {
                  if (searching) {
                    return <SearchFilterIndicator size='regular' />;
                  }
                  if (searchError) {
                    return <LoadingFilterError />;
                  }
                  if (filterSearched && !filterOptions.length) {
                    return <EmptyFilter />;
                  }
                  return (
                    <Box
                      className='u-flex u-flex-align-center filter-checkbox-wrapper'
                      key={item[filter.value]}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            { ...props}
                            checked={selected}
                            classes={{
                              root: 'checkbox-root'
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={filter.columnType !== 'url' ? `${item[filter.value] ? item[filter.value] : 'NULL'}` :
                          `${item[filter.value] ? item[filter.value].split('-*-')[0] : 'NULL'}`}
                        name={item[filter.value] || ''}
                        value={item[filter.value]}
                      />
                    </Box>
                  );
                }}
                classes={{
                  inputRoot: !!anchorEl && !!filterOptions.length ? 'multi-select-input-prefix' : 'multi-select-input-prefix no-radius',
                  listbox: 'multi-select-paper'
                }}
                filterOptions={(item: any) => item}
                isOptionEqualToValue={(option: any, value: any) => option[filter.value] === value[filter.value]}
                onChange={(event: React.ChangeEvent<{}>, items: any, reason: AutocompleteChangeReason) => handleChangeFilterValue(items, reason)}
                renderTags={() => null}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  isDropdownOpen ? (
                    <TextField
                      {...params}
                      color='info'
                      placeholder={!filterValue.length ? 'Search' : ''}
                      fullWidth
                      className='full-width filter-text-input'
                      size='small'
                      value={searchValue}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchValue(event.target.value);
                        debouncedSearch(event.target.value);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: '48px',
                        },
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={() => {
                              setSearchValue('');
                              debouncedSearch('');
                            }}>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  ) : null
                )}
              />
            </Box>
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default SearchMultiSelectPrefixTechnologyFilter;

import type { NextPage} from 'next';
import { useState, useEffect, useRef } from 'react';
import {
  Box, Select, InputBase, MenuItem, ListItemText, Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams,
  AutocompleteRenderGetTagProps, Chip, Tooltip, SelectChangeEvent, TextField, Typography, Paper
} from '@mui/material';
import classes from './filterField.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { AudienceFilter, Filter, Relation } from '../../types/Cube.interfaces';
import { ResultSet, Query, BinaryOperator } from '@cubejs-client/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import LoadingFilterError from '../loadingFilterError/loadingFilterError';
import EmptyFilter from '../emptyFilter/emptyFilter';
import SearchFilterIndicator from '../searchFilterIndicator/searchFilterIndicator';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';

interface Props {
  filter: AudienceFilter;
  filterItem: Filter;
  handleAddNewFilter: () => void;
  lastFilter: boolean;
  removeIcon: boolean;
  handleRemoveFilter: (position: number) => void;
  filterPosition: number;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
  hasOperatorBefore: boolean;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  table: string;
  clearFilters: boolean;
  setFilterChanged: (value: boolean) => void;
  anchorEl: any;
  partialOperators: boolean;
}

const FilterField: NextPage<Props> = ({
  filter, filterItem, handleAddNewFilter, hasOperatorBefore, audienceFilters, setAudienceFilters, clearFilters, anchorEl,
  lastFilter, removeIcon, handleRemoveFilter, filterPosition, setFilterGroup, filterGroup, table, setFilterChanged, partialOperators,
}) => {
  const { handleAddFilter, modifyFilters, handleRemoveAudienceFilter, handleRemoveFilterValue, modifyJobTitleFilter } = useBasicFiltersState();
  const [localSuggestions, setLocalSuggestions] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [relationValue, setRelationValue] = useState<BinaryOperator>('contains');
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [relations, setRelations] = useState<{ title: string, value: BinaryOperator }[]>([
    { title: 'Is', value: 'equals' },
    { title: 'Is not', value: 'notEquals' },
    { title: 'Contains', value: 'contains' },
    { title: 'Doesn\'t contain', value: 'notContains' },
    { title: 'Starts with', value: 'startsWith' },
  ]);
  const [open, setOpen] = useState<boolean>(false);
  const [filterSearched, setFilterSearched] = useState<boolean>(false);
  const inputRef = useRef();
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (!anchorEl) {
      setSearchValue('');
      setLocalSuggestions([]);
      setFilterSearched(false);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (partialOperators) {
      setRelations([
        { title: 'Contains', value: 'contains' },
        { title: 'Doesn\'t contain', value: 'notContains' },
        { title: 'Starts with', value: 'startsWith' },
      ]);
    } else {
      setRelations([
        { title: 'Is', value: 'equals' },
        { title: 'Is not', value: 'notEquals' },
        { title: 'Contains', value: 'contains' },
        { title: 'Doesn\'t contain', value: 'notContains' },
        { title: 'Starts with', value: 'startsWith' },
      ]);
    }
  }, [partialOperators]);

  useEffect(() => {
    if (filter) {
      const values = filter.values.map((item: string) => ({ [filter.member as string]: item }));
      setFilterValue([ ...values ]);
      if (partialOperators) {
        setRelationValue(filter.operator !== 'equals' && filter.operator !== 'notEquals' ? filter.operator : 'contains');
      } else {
        setRelationValue(filter.operator);
      }
    }
  }, [filter, partialOperators]);

  useEffect(() => {
    if (clearFilters) {
      setFilterValue([]);
    }
  }, [clearFilters]);

  const handleFilterOptions = async (search: string) => {
    const column = filterItem.value.split('.').length > 1 ? filterItem.value.split('.')[1] : null;
    if (column !== 'about_us_org') {
      if (search.length > 0) {
        try {
          setSearchError(false);
          setLocalLoading(true);
          const modifiedFilters = modifyFilters(audienceFilters);
          const filterKey = filterItem.searchColumn ? filterItem.searchColumn : filterItem.value;
          const query: Query = {
            measures: [ filterItem.searchColumn ? `${filterItem.searchColumn.split('.')[0]}.count` : `${table.toUpperCase()}.count` ],
            dimensions: [ filterKey ],
            filters: modifiedFilters.length > 0 ? [
              ...(modifyJobTitleFilter(modifiedFilters) as AudienceFilter[]).filter((item: AudienceFilter) => {
                const splitValue = item.member && item.member.split('.')[1];
                const forwardedFilterSplitValue = filterItem.value.split('.')[1];
                return splitValue && splitValue !== forwardedFilterSplitValue;;
              }),
              {
                member: filterKey,
                operator: 'contains',
                values: [ search ],
              }
            ] : [
              {
                member: filterKey,
                operator: 'contains',
                values: [ search ],
              }
            ],
            limit: 1000,
          };
          const resultSet: ResultSet<any> = await cubejsApiWrapper(query);
          setLocalSuggestions([
            (resultSet.tablePivot() && resultSet.tablePivot().length && resultSet.tablePivot().some(el => el[filterKey] === search) ?
              null :
              { [filterItem.value]: search }),
            ...resultSet.tablePivot().map((item: any) => (
              { [filterItem.value as string]: item[filterKey] }
            ))
          ]);
          setFilterSearched(true);
        } catch (error) {
          setLocalSuggestions([]);
          setSearchError(true);
          setFilterSearched(true);
        } finally {
          setLocalLoading(false);
        }
      } else if (search.length === 0) {
        setLocalSuggestions([]);
        setFilterSearched(false);
      }
    } else {
      setSearchError(false);
      setLocalSuggestions([{ [filterItem.value]: search }]);
    }
  };

  const debouncedSearch = useDebouncedCallback(handleFilterOptions, 700);

  const handleChangeFilterValue = (items: any, reason: AutocompleteChangeReason) => {
    if (filterItem) {
      const newItems = items.map((value: any) => typeof value === 'string' ? value : value[filterItem.value]);
      switch (reason) {
        case 'createOption':
          const data = filterValue;
          filter.values = [...newItems];
          setFilterGroup([...filterGroup]);
          data.push({ [filterItem.value]: searchValue });
          setFilterValue([ ...data ]);
          const newFilter: AudienceFilter = {
            member: filterItem.value,
            operator: relationValue,
            values: [ ...newItems ]
          };
          handleAddFilter(newFilter, audienceFilters, setAudienceFilters);
          setLocalSuggestions([]);
          setSearchValue('');
          setFilterSearched(false);
          break;
        case 'selectOption':
          filter.values = newItems.length ? newItems : [];
          setFilterGroup([...filterGroup]);
          const filterToAdd = {
            member: filterItem.value,
            operator: relationValue,
            values: newItems
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
          setSearchValue('');
          setFilterValue(items);
          setLocalSuggestions([]);
          setFilterSearched(false);
          break;
        case 'removeOption':
          const previousValues = filter.values;
          setSearchValue('');
          if (!newItems.length) {
            filter.values = [];
            setFilterValue([]);
            setFilterGroup([...filterGroup]);
            const filterToRemove: AudienceFilter = {
              member: filterItem.value,
              operator: relationValue,
              values: previousValues
            };
            handleRemoveAudienceFilter(filterToRemove, audienceFilters, setAudienceFilters);
            setLocalSuggestions([]);
          } else {
            filter.values = newItems;
            setFilterGroup([...filterGroup]);
            setFilterValue(items);
            const removeFilter: AudienceFilter = {
              member: filterItem.value,
              operator: relationValue,
              values: previousValues
            };
            handleRemoveFilterValue(removeFilter, newItems, audienceFilters, setAudienceFilters);
          }
          if (!localSuggestions.length) {
            setFilterSearched(false);
          }
          break;
        case 'clear':
          const prevValues = filter.values;
          if (prevValues.length) {
            filter.values = [];
            setFilterGroup([...filterGroup]);
            setLocalSuggestions([]);
            setSearchValue('');
            setFilterValue([]);
            const oldFilter: AudienceFilter = {
              member: filterItem.value,
              operator: relationValue,
              values: prevValues
            };
            handleRemoveAudienceFilter(oldFilter, audienceFilters, setAudienceFilters);
            setFilterSearched(false);
          }
          break;
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const updateFilterOperator = (operator: BinaryOperator, filterType: string) => {
    setRelationValue(operator);
    const foundFilter = audienceFilters
      .find((item: AudienceFilter) => {
        return item.member === filterType && item.values && item.values.every((value: string) => filter.values.includes(value));
      });
    filter.operator = operator;
    setFilterGroup([...filterGroup]);
    if (filterValue.length > 0) {
      if (foundFilter) {
        foundFilter.operator = operator;
        setAudienceFilters([...audienceFilters]);
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const getFilterLabel = (option: any): string => {
    if (filterItem.columnType !== 'url') {
      if (typeof option === 'string') {
        return option;
      } else {
        return option[filterItem.value] ? option[filterItem.value] : 'NULL';
      }
    } else {
      if (typeof option === 'string') {
        return option.split('-*-')[0];
      } else {
        return option[filterItem.value] ? option[filterItem.value].split('-*-')[0] : 'NULL';
      }
    }
  };

  return (
    <Box>
      {hasOperatorBefore && filterPosition !== 0 ? (
        <Box sx={{ flex: '1 1 10%', paddingLeft: '16px' }}>
          <Typography>
            {filter.operator === filterGroup[filterPosition - 1].operator ? 'OR' : 'AND'}
          </Typography>
        </Box>
      ) : null}
      <Box className={classes.textFieldWrapper}>
        <Box sx={{ flex: '1 1 20%', alignSelf: 'flex-start' }}>
          <Select
            labelId='relation-label'
            id='relation-group'
            value={relationValue}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            margin='dense'
            variant='outlined'
            onChange={(e: SelectChangeEvent<any>) => {
              updateFilterOperator(e.target.value, filterItem.value);
              if (searchValue.length > 0 || filterValue.length > 0) {
                debouncedSearch(searchValue);
              }
            }}
            classes={{ select: 'select' }}
            input={
              <InputBase
                className='select-input select-input-partial'
                sx={{
                  height: '48px',
                  borderBottomLeftRadius: open ? '0px !important' : '4px !important',
                }}
              />
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              classes: {
                paper: 'select-mui-root'
              }
            }}
          >
            {relations.map((item: Relation, index: number) => (
              <MenuItem key={index} value={item.value} dense={true} sx={{ padding: '2px 8px !important' }}>
                <ListItemText primary={item.title} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ flex: '1 1 60%', minWidth: '300px' }}>
          <Autocomplete
            multiple
            freeSolo
            sx={{  minHeight: '48px' }}
            ref={inputRef}
            value={filterValue}
            options={localSuggestions.filter(el => el)}
            open={!!anchorEl}
            forcePopupIcon={true}
            id={`filter-${filterItem.value}-${Math.random()}`}
            size='small'
            noOptionsText='No options'
            inputValue={searchValue}
            getOptionLabel={(item: any) => item[filterItem.value] ? `${item[filterItem.value]}` : ''}
            renderOption={(props: any, item: any, { selected }) => (
              <MenuItem
                {...props}
                dense
                key={Math.random()}
                sx={{ backgroundColor: selected ? '#A0A5AC !important' : ''}}
              >
                {filterItem.columnType !== 'url' ? `${item[filterItem.value] ? item[filterItem.value] : 'NULL'}` :
                  `${item[filterItem.value] ? item[filterItem.value].split('-*-')[0] : 'NULL'}`}
              </MenuItem>
            )}
            classes={{
              inputRoot: !!localSuggestions.length ? 'multi-select-input-prefix' : 'multi-select-input-prefix no-radius',
              listbox: 'multi-select-paper-partial',
            }}
            filterOptions={(item: any) => item}
            isOptionEqualToValue={(option: any, value: any) => option[filterItem.value] === value[filterItem.value]}
            onChange={(event: React.ChangeEvent<{}>, items: any, reason: AutocompleteChangeReason) => handleChangeFilterValue(items, reason)}
            PaperComponent={({ children }) => (
              <Paper sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                {localLoading ? (
                  <SearchFilterIndicator size='regular' />
                ) : (
                  searchError ? (
                    <LoadingFilterError />
                  ) : (
                    filterSearched ? (
                      !localSuggestions.length ? (
                        <EmptyFilter />
                      ) : (
                        children
                      )
                    ) : (
                      children
                    )
                  )
                )}
              </Paper>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                sx={{  minHeight: '48px' }}
                color='info'
                placeholder={!filterValue.length ? 'Search' : ''}
                fullWidth
                className='full-width search-input-partial'
                value={searchValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchValue(event.target.value);
                  debouncedSearch(event.target.value);
                }}
              />
            )}
            renderTags={(value: any[], getTagProps: AutocompleteRenderGetTagProps) =>
              value.map((option: any, index: number) => {
                const { className, disabled, tabIndex, onDelete } = getTagProps({ index });
                return (
                  <Chip
                    color='primary'
                    key={index}
                    className={className}
                    disabled={disabled}
                    tabIndex={tabIndex}
                    onDelete={onDelete}
                    size='small'
                    label={getFilterLabel(option)}
                  />
                );
              })
            }
          />
        </Box>
        <Box className={classes.addIconWrapper}>
          {removeIcon ? (
            <Tooltip title='Remove' placement='bottom' classes={{ tooltip: 'custom-tooltip' }}>
              <DeleteOutlineIcon
                onClick={() => {
                  handleRemoveFilter(filterPosition);
                  if (filterValue.length) {
                    setFilterChanged(true);
                    setTimeout(() => {
                      setFilterChanged(false);
                    }, 100);
                  }
                }}
                sx={{ fontSize: '24px', color: '#42474E', cursor: 'pointer', marginRight: '16px' }}
              />
            </Tooltip>
          ) : null}
          {lastFilter ? (
            <Tooltip title='Add' placement='bottom' classes={{ tooltip: 'custom-tooltip' }}>
              <AddIcon
                onClick={() => handleAddNewFilter()}
                sx={{ fontSize: '24px', color: '#42474E', cursor: 'pointer' }}
              />
            </Tooltip>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default FilterField;
import type { NextPage } from 'next';
import {
  Box, ClickAwayListener, Popper, Select, InputBase, ListItemText, MenuItem, SelectChangeEvent, OutlinedInput, IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useState, useEffect } from 'react';
import { BinaryOperator } from '@cubejs-client/core';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { useDebouncedCallback } from 'use-debounce';
import { useUiState } from '../../context/uiState';


interface Props {
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filter: Filter;
  filterItem: AudienceFilter;
  filterGroup: AudienceFilter[];
  setFilterGroup: (value: AudienceFilter[]) => void;
  clearFilters: boolean;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
}

const NumericalFilter: NextPage<Props> = ({
  anchorEl, handleClose, filter , filterItem, filterGroup, setFilterGroup, clearFilters, audienceFilters, setAudienceFilters, setFilterChanged
}) => {
  const { displayToast } = useUiState();
  const { handleAddNumericFilter, handleRemoveAudienceFilter } = useBasicFiltersState();
  const [filterValue, setFilterValue] = useState<any>(null);
  const [relationValue, setRelationValue] = useState<BinaryOperator>('equals');
  const [queryValue, setQueryValue] = useState<string>('');
  const [relations] = useState<{ title: string, value: BinaryOperator }[]>([
    { title: 'Is', value: 'equals' },
    { title: 'Greater than', value: 'gt' },
    { title: 'Less than', value: 'lt' }
  ]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (filterItem.values.length) {
      setRelationValue(filterItem.operator);
      setFilterValue([...filterItem.values.map((value: string) => value )]);
      setQueryValue(filterItem.values.map((value: string) => value)[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItem]);

  useEffect(() => {
    if (clearFilters) {
      setFilterValue([]);
      setQueryValue('');
    }
  }, [clearFilters]);

  const updateFilterOperator = (operator: BinaryOperator, filterType: string) => {
    setRelationValue(operator);
    const foundFilter = audienceFilters
      .find((item: AudienceFilter) => {
        return item.member === filterType && item.values && item.values.every((value: string) => filterItem.values.includes(value));
      });
    filterItem.operator = operator;
    setFilterGroup([...filterGroup]);
    if (filterValue && filterValue.length > 0) {
      if (foundFilter) {
        foundFilter.operator = operator;
        setAudienceFilters([...audienceFilters]);
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const handleChangeFilterValue = (value: string, reason: string) => {
    if (filter) {
      switch(reason) {
        case 'select':
          filterItem.values = value.length ? [value] : [];
          setFilterGroup([...filterGroup]);
          const filterToAdd: AudienceFilter = {
            member: filter.value,
            operator: relationValue,
            values: [value]
          };
          handleAddNumericFilter(filterToAdd, audienceFilters, setAudienceFilters);
          setFilterValue(value);
          break;
        case 'clear':
          const prevValues = filterItem.values;
          if (prevValues.length) {
            filterItem.values = [];
            setFilterGroup([...filterGroup]);
            setQueryValue('');
            setFilterValue([]);
            handleRemoveAudienceFilter(
              { member: filter.value, operator: relationValue, values: prevValues }, audienceFilters, setAudienceFilters
            );
          }
          break;
        default:
          displayToast('Option not valid!', 'warning');
          break;
      }
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 100);
  };

  const debouncedSearch = useDebouncedCallback(handleChangeFilterValue, 700);

  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box>
          <Box className='numerical-wrapper'>
            <Box className='prefix-wrapper' sx={{ flex: '1 1 20%' }}>
              <Select
                labelId='relation-label'
                id='relation-group'
                value={relationValue}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                margin='dense'
                variant='outlined'
                onChange={(e: SelectChangeEvent<any>) => {
                  updateFilterOperator(e.target.value, filter.value);
                  if (queryValue.length > 0) {
                    debouncedSearch(queryValue, 'select');
                  }
                }}
                classes={{ select: 'select' }}
                input={
                  <InputBase
                    className='select-input'
                    sx={{
                      height: '100%',
                      borderBottomLeftRadius: open ? '0px !important' : '4px !important'
                    }}
                  />
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  classes: {
                    paper: 'select-mui-root'
                  }
                }}
              >
                {relations.map((item: { title: string, value: BinaryOperator }, index: number) => (
                  <MenuItem key={index} value={item.value} dense={true} sx={{ padding: '2px 8px !important' }}>
                    <ListItemText primary={item.title} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ flex: '1 1 80%', minWidth: '300px' }}>
              <OutlinedInput
                inputProps={{ inputMode: 'numeric'}}
                size='small'
                color='info'
                placeholder={'Input a number'}
                fullWidth
                className='full-width numerical-input input-box-shadow'
                value={queryValue}
                endAdornment={
                  <IconButton sx={{display: queryValue ?  '': 'none'}} onClick={() => handleChangeFilterValue(queryValue, 'clear')}>
                    <ClearIcon/>
                  </IconButton>
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQueryValue(event.target.value);
                  debouncedSearch(event.target.value, 'select');
                }}
              />
            </Box>
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default NumericalFilter;

import type { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { Box, Checkbox, Popper, ClickAwayListener, FormControlLabel, Skeleton, Tooltip } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { UnaryOperator, BinaryFilter } from '@cubejs-client/core';
import LoadingFilterError from '../loadingFilterError/loadingFilterError';
import EmptyFilter from '../emptyFilter/emptyFilter';
import PredefinedFilterChips from '../predefinedFilterChips/predefinedFilterChips';

interface Props {
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  options: { [key: string]: string }[];
  filter: Filter;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
  filterItem: AudienceFilter;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
  searchError: boolean;
  filterSearched: boolean;
}

const PredefinedCheckboxFilter: NextPage<Props> = ({
  anchorEl, handleClose, options, filter, audienceFilters, setAudienceFilters, setFilterChanged, filterItem,
  setFilterGroup, filterGroup, searchError, filterSearched
}) => {
  const { handleAddFilter, handleRemoveAudienceFilter, handleRemoveFilterValue } = useBasicFiltersState();
  const [filterCheckedValue, setFilterCheckedValue] = useState<(string | null)[]>([]);
  const [sortedOptions, setSortedOptions] = useState<{ [key: string]: string }[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const [chipsWrapperWidth, setChipsWrapperWidth] = useState<number>(0);

  useEffect(() => {
    if (filter.title && anchorEl) {
      const width = filter.title.length * 15 < 300 ? 300 : filter.title.length * 15;
      setChipsWrapperWidth(width);
    }
  }, [filter.title, anchorEl]);

  useEffect(() => {
    if (anchorEl && filter && audienceFilters && audienceFilters.length) {
      const findFilters = audienceFilters.filter((item: AudienceFilter) => item.member && item.member.split('.')[1] === filter.value.split('.')[1]);
      if (findFilters && findFilters.length) {
        const values: (string | null)[] = [];
        findFilters.forEach((filter: AudienceFilter) => {
          filter.values.forEach((value: string) => {
            values.push(value ? value : null);
          });
        });
        setFilterCheckedValue(values);
      }
    } else {
      setFilterCheckedValue([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, anchorEl]);

  useEffect(() => {
    if (filter && (filter.title.toLowerCase() === 'status' || filter.title.toLowerCase() === 'email status')) {
      const newOptions = [...options];
      setSortedOptions(newOptions.sort((aValue: { [key: string]: string }, bValue: { [key: string]: string }) => {
        return bValue[filter.value].localeCompare(aValue[filter.value]);
      }));
    } else {
      setSortedOptions(options);
    }
  }, [options, filter]);

  const filterChanged = (filterName: UnaryOperator, checked: boolean, filterValue: string) => {
    if (checked) {
      const isNullAddedIndex = filterItem.values.findIndex((value: string | null) => value === null);
      if (isNullAddedIndex > -1) {
        if (filterCheckedValue.length === 1) {
          const filterToRemove: BinaryFilter = {
            member: filter.value,
            operator: 'equals',
            values: [...filterItem.values]
          };
          handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [...filterItem.values, filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        } else {
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [...filterItem.values, filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        }
      } else {
        if (filterValue) {
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        } else {
          const filterToRemove: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [...filterItem.values]
          };
          handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: filterItem.values.length ? 'contains' : 'equals',
            values: [...filterItem.values, filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        }
      }
      filterItem.values = [ ...new Set([ ...filterItem.values, filterValue])];
      setFilterGroup([...filterGroup]);
      setFilterCheckedValue(prevValue => ([...new Set([...prevValue, filterName ? filterName : filterValue])]));
    } else {
      if (filterItem.values.length === 1) {
        setFilterCheckedValue([]);
        const filterToRemove: BinaryFilter = {
          member: filter.value,
          operator: filterItem.values[0] ? 'contains' : 'equals',
          values: [...filterItem.values]
        };
        handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
        filterItem.values = [];
        setFilterGroup([...filterGroup]);
      } else if (filterItem.values.length === 2) {
        const isNullAddedIndex = filterItem.values.findIndex((value: string | null) => value === null);
        if (isNullAddedIndex > -1) {
          if (!filterValue) {
            const previousValues = filterItem.values;
            const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
            setFilterCheckedValue([...newValues]);
            const filterToRemove: BinaryFilter = {
              member: filter.value,
              operator: 'contains',
              values: previousValues
            };
            handleRemoveFilterValue(filterToRemove, newValues, audienceFilters, setAudienceFilters);
          } else {
            const previousValues = filterItem.values;
            const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
            setFilterCheckedValue([...newValues]);
            const filterToRemove: BinaryFilter = {
              member: filter.value,
              operator: 'contains',
              values: previousValues
            };
            handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
            const filterToAdd: BinaryFilter = {
              member: filter.value,
              operator: 'equals',
              values: [...previousValues.filter((value: string) => value !== filterValue)]
            };
            handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
          }
        } else {
          const previousValues = filterItem.values;
          const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
          setFilterCheckedValue([...newValues]);
          const filterToRemove: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: previousValues
          };
          handleRemoveFilterValue(filterToRemove, newValues, audienceFilters, setAudienceFilters);
        }
        filterItem.values = [...filterItem.values.filter((value: string) => value !== filterValue)];
        setFilterGroup([...filterGroup]);
      } else if (filterItem.values.length > 2) {
        const previousValues = filterItem.values;
        const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
        setFilterCheckedValue([...newValues]);
        const filterToRemove: BinaryFilter = {
          member: filter.value,
          operator: 'contains',
          values: previousValues
        };
        handleRemoveFilterValue(filterToRemove, newValues, audienceFilters, setAudienceFilters);
        filterItem.values = [...filterItem.values.filter((value: string) => value !== filterValue)];
        setFilterGroup([...filterGroup]);
      }
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 100);
  };

  const checkValue = (item: string): boolean => {
    const value = filterCheckedValue.find((value: string | null) => value === item);
    if (value || value === '' || value === null) {
      return true;
    } else {
      return false;
    }
  };

  const tooltipTexts: {[key: string]: string} = {
    'Verified': 'Email account is verified & exists',
    'Not Available': 'No email available',
    'Limited Info': 'Unable to verify email, catch-all email domains',
  };

  const handleTooltipText = (tooltipText: string): string => {
    return tooltipTexts[tooltipText] || '';
  };

  const getLabel = (item: string): string => {
    return item ? item : 'Is unknown';
  };

  const handleClearAll = () => {
    let updatedFilters = [...audienceFilters];
    filterItem.values.forEach(option => {
      updatedFilters = updatedFilters.filter(item => {
        return !(item.member === filter.value && item.values.includes(option));
      });
    });
    setAudienceFilters(updatedFilters);
    filterItem.values = [];
    setFilterCheckedValue([]);
  };


  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='checkbox-multi-select'>
          <PredefinedFilterChips
            minWidth={160}
            width={chipsWrapperWidth}
            filterValue={filterItem.values}
            filter={filter}
            getLabel={getLabel}
            onFilterChange={filterChanged}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            handleClearAll={handleClearAll}
          />
          {isDropdownOpen ? (
            <Box
              sx={{ width: chipsWrapperWidth + 'px', minWidth: '160px' }}
              className='u-p-12 predefined-checkbox-wrapper'
            >
              {searchError ? (
                <LoadingFilterError />
              ) : (
                filterSearched ? (
                  !sortedOptions.length ? (
                    <EmptyFilter />
                  ) : (
                    <>
                      {sortedOptions.map((item: { [key: string]: string }, index: number) => (
                        <Box
                          key={index}
                          className='u-flex u-flex-align-center filter-checkbox-wrapper'
                        >
                          <Tooltip
                            placement='right'
                            title={filter.value === 'TAM.email_status_per' ? handleTooltipText(item[filter.value]) : ''}
                          >
                            <FormControlLabel
                              className='checkbox-button'
                              checked={checkValue(item[filter.value])}
                              control={
                                <Checkbox
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    filterChanged(event.target.name as UnaryOperator, event.target.checked, item[filter.value]);
                                  }}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  classes={{
                                    root: 'checkbox-root'
                                  }}
                                />
                              }
                              label={item[filter.value] ? item[filter.value] : 'Is unknown'}
                              name={item[filter.value] || ''}
                              value={item[filter.value]}
                            />
                          </Tooltip>
                        </Box>
                      ))}
                    </>
                  )
                ) : (
                  Array.from(Array(3).keys()).map((value: number) => (
                    <Skeleton key={value + 1} variant='rectangular' height={30} className='u-mb-6' />
                  ))
                )
              )}
            </Box>
          ) : null}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default PredefinedCheckboxFilter;

import type { NextPage } from 'next';
import { Box, Menu } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import FilterField from '../filterField/filterField';

interface Props {
  filter: Filter;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  table: string;
  handleAddNewFilter: () => void;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  handleRemoveFilter: (value: number) => void;
  clearFilters: boolean;
  setFilterChanged: (value: boolean) => void;
  partialOperators: boolean;
}

const SearchPartialMultiSelectFilter: NextPage<Props> = ({
  filter, filterGroup, setFilterGroup, anchorEl, handleClose, table, handleAddNewFilter,
  audienceFilters, setAudienceFilters, handleRemoveFilter, clearFilters, setFilterChanged, partialOperators
}) => {
  return (
    <Menu
      elevation={0}
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={(event: any) => handleClose(event)}
      classes={{
        list: 'partial-menu-list'
      }}
      autoFocus={false}
      MenuListProps={{
        variant: 'menu'
      }}
    >
      <Box className='partial-multi-select' sx={{ zIndex: '1000' }}>
        <Box className='u-flex u-flex-direction-column'>
          {filterGroup.map((item: AudienceFilter, index: number) => (
            <FilterField
              key={`${filter.value}-${index}`}
              filterItem={filter}
              handleAddNewFilter={handleAddNewFilter}
              filter={item}
              lastFilter={index === filterGroup.length - 1}
              removeIcon={filterGroup.length > 1}
              filterPosition={index}
              handleRemoveFilter={handleRemoveFilter}
              setFilterGroup={setFilterGroup}
              filterGroup={filterGroup}
              hasOperatorBefore={filterGroup.length > 1}
              audienceFilters={audienceFilters}
              setAudienceFilters={setAudienceFilters}
              table={table}
              clearFilters={clearFilters}
              setFilterChanged={setFilterChanged}
              anchorEl={anchorEl}
              partialOperators={partialOperators}
            />
          ))}
        </Box>
      </Box>
    </Menu>
  );
};

export default SearchPartialMultiSelectFilter;
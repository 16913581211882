import { NextPage } from 'next';
import { Box, Chip, Button, IconButton, Typography } from '@mui/material';
import { Filter } from '../../types/Cube.interfaces';
import { UnaryOperator } from '@cubejs-client/core';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  minWidth: number;
  width: number;
  filterValue: string[];
  filter: Filter;
  getLabel: (option: any) => string;
  onFilterChange: (filterName: UnaryOperator, checked: boolean, filterValue: string) => void;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
  handleClearAll: () => void;
}

const PredefinedFilterChips: NextPage<Props> = ({
  minWidth,
  width,
  filterValue,
  filter,
  getLabel,
  onFilterChange,
  isDropdownOpen,
  setIsDropdownOpen,
  handleClearAll
}) => {


  return (
    <Box
      sx={{ minWidth: minWidth + 'px', width: width + 'px' }}
      className='filter-chips-wrapper'
    >
      <Box className='u-flex u-flex-space-between'>
        <Box className='u-flex u-flex-align-center u-flex-wrap chips-wrapper'>
          {filterValue.length ? (
            filterValue.map((option: string, index: number) => (
              <Chip
                color='primary'
                key={index}
                size='small'
                className='u-mr-2 u-mb-2'
                label={getLabel(option)}
                onDelete={
                  () => onFilterChange(option as UnaryOperator, false, option)
                }
              />
            ))
          ) : (
            <Typography className='u-ml-8'>
                Select {filter.title}
            </Typography>
          )}
          {filterValue.length > 1 && (
            <Button
              sx={{ textTransform: 'none' }}
              size='small'
              variant='outlined'
              className='u-mr-2 u-mb-2 u-mt-2 u-br-20-px u-py-0'
              onClick={handleClearAll}
            >
              Clear all
            </Button>
          )}
        </Box>
        <Box className='u-flex u-flex-align-center u-mx-4'>
          <IconButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {isDropdownOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PredefinedFilterChips;

import type { NextPage } from 'next';
import { Box, Chip, Button, Typography, IconButton, AutocompleteChangeReason } from '@mui/material';
import { Filter } from '../../types/Cube.interfaces';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


interface Props {
  filterValue: any[];
  filter: Filter;
  isDropdownOpen: boolean;
  minWidth: number;
  width: number;
  filterOptions: boolean;
  getLabel: (option: any) => string;
  handleChangeFilterValue: (items: any, reason: AutocompleteChangeReason) => void;
  setIsDropdownOpen: (isOpen: boolean) => void;
}

const FilterChips: NextPage<Props> = ({
  filterValue,
  filter,
  isDropdownOpen,
  minWidth,
  width,
  filterOptions,
  getLabel,
  handleChangeFilterValue,
  setIsDropdownOpen,
}) => {


  return (
    <Box
      sx={{ minWidth: minWidth + 'px', width: width + 'px' }}
      className='filter-chips-wrapper'
    >
      <Box className='u-flex u-flex-space-between'>
        <Box className='u-flex u-flex-align-center u-flex-wrap chips-wrapper'>
          {filterValue.length ?
            null : (
              <Typography className='u-ml-8'>
                {filterOptions ? 'Select' : 'Search'} {filter.title}
              </Typography>
            )}
          {filterValue.map((option: any, index: number) => (
            <Chip
              color='primary'
              key={index}
              size='small'
              className='u-mr-2 u-mb-2'
              label={getLabel(option)}
              onDelete={
                () => handleChangeFilterValue(filterValue.filter((item: any, itemIndex: number) => itemIndex !== index), 'removeOption')
              }
            />
          ))}
          {filterValue.length > 1 ? (
            <Button
              sx={{ textTransform: 'none'  }}
              size='small'
              variant='outlined'
              className='u-mr-2 u-mb-2 u-mt-2 u-br-20-px u-py-0'
              onClick={() => handleChangeFilterValue([], 'clear')}
            >
              Clear all
            </Button>
          ) : null}
        </Box>
        <Box className='u-flex u-flex-align-center u-mx-4'>
          <IconButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {isDropdownOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterChips;

import type { NextPage } from 'next';
import { useState, useEffect, useCallback } from 'react';
import {
  Box, Autocomplete, TextField, Popper, AutocompleteRenderInputParams, AutocompleteChangeReason,
  ClickAwayListener, PopperProps, Checkbox, FormControlLabel
} from '@mui/material';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import LoadingFilterError from '../loadingFilterError/loadingFilterError';
import EmptyFilter from '../emptyFilter/emptyFilter';
import SearchFilterIndicator from '../searchFilterIndicator/searchFilterIndicator';
import FilterChips from '../filterChips/filterChips';
interface Props {
  options: any[];
  filter: Filter;
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filterItem: AudienceFilter;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
  clearFilters: boolean;
  audienceFilters: AudienceFilter[];
  loading: boolean;
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
  searchError: boolean;
  filterSearched: boolean;
}

const MultiSelectFilter: NextPage<Props> = ({
  options, filter, anchorEl, handleClose, filterItem, setFilterGroup, filterGroup, clearFilters, setFilterChanged,
  audienceFilters, loading, setAudienceFilters, searchError, filterSearched
}) => {
  const { handleAddFilter, handleRemoveAudienceFilter, handleRemoveFilterValue } = useBasicFiltersState();
  const [filterValue, setFilterValue] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [filterOptionsCopy, setFilterOptionsCopy] = useState<any[]>([]);
  const [employeeCountSort] = useState<{ [key: string]: number }>({
    null: 190,
    '1 to 10': 180,
    '1 to 19': 170,
    '11 to 50': 160,
    '20 to 99': 150,
    '51 to 200': 140,
    '100 to 499': 130,
    '201 to 500': 120,
    '500 to 999': 110,
    '501 to 1000': 100,
    '1000 to 4999': 90,
    '1001 to 5000': 80,
    '5000+': 70,
    '5000 to 9999': 60,
    '5001 to 10000': 50,
    '10000+': 40,
    '10001+': 30,
  });
  const [revenueSort] = useState<{ [key: string]: number }>({
    null: 110,
    '<$1M': 100,
    '$1M to <$10M': 90,
    '$10M to <$50M': 80,
    '$50M to <$100M': 70,
    '$100M to <$1B': 60,
    '$1B+': 50,
  });
  const [jobLevelSort] = useState<{ [key: string]: number }>({
    'C-Team': 100,
    'Director': 90,
    'VP': 80,
    'Manager': 70,
    'Staff': 60,
    'Consultant': 50,
  });
  const [implyIndustrySort] = useState<{ [key: string]: number }>({
    'Ad-Tech': 100,
    'Fin-Tech': 90,
    'IoT': 80,
    'Hardware': 70,
    'Telecommunications': 60,
    'E-Commerce': 50,
    'Software': 40,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const [searching, setSearching] = useState<boolean>(false);
  const [chipsWrapperWidth, setChipsWrapperWidth] = useState<number>(0);

  useEffect(() => {
    if (filter.title && anchorEl) {
      const width = filter.title.length * 15 < 300 ? 300 : filter.title.length * 15;
      setChipsWrapperWidth(width);
    }
  }, [filter.title, anchorEl]);

  useEffect(() => {
    if (filterItem.values.length) {
      setFilterValue([...filterItem.values.map((value: string) => ({ [filter.value]: value }))]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItem]);

  useEffect(() => {
    if (options.length) {
      if (filter.value.includes('employee_count_range_org')) {
        const newOptions = options.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const aValue = employeeCountSort[a[filter.value]] || 40;
          const bValue = employeeCountSort[b[filter.value]] || 40;
          return bValue - aValue;
        });
        setFilterOptions(newOptions);
        setFilterOptionsCopy(newOptions);
      } else if (filter.value.includes('revenue_range_org')) {
        const newOptions = options.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const aValue = revenueSort[a[filter.value]] || 40;
          const bValue = revenueSort[b[filter.value]] || 40;
          return bValue - aValue;
        });
        setFilterOptions(newOptions);
        setFilterOptionsCopy(newOptions);
      } else if (filter.value.includes('job_level_per')) {
        const newOptions = options.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const aValue = jobLevelSort[a[filter.value]] || 40;
          const bValue = jobLevelSort[b[filter.value]] || 40;
          return bValue - aValue;
        });
        setFilterOptions(newOptions);
        setFilterOptionsCopy(newOptions);
      } else if (filter.value.includes('member_count_org')) {
        const newOptions = options.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const aValue = a[filter.value] ? +a[filter.value] : 0;
          const bValue = b[filter.value] ? +b[filter.value] : 0;
          return aValue - bValue;
        });
        setFilterOptions(newOptions);
        setFilterOptionsCopy(newOptions);
      } else if (filter.value.includes('imply_industry')) {
        const newOptions = options.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const aValue = implyIndustrySort[a[filter.value]] || 30;
          const bValue = implyIndustrySort[b[filter.value]] || 30;
          return bValue - aValue;
        });
        setFilterOptions(newOptions);
        setFilterOptionsCopy(newOptions);
      } else {
        setFilterOptions(options);
        setFilterOptionsCopy(options);
      }
    } else {
      setFilterOptions([]);
      setFilterOptionsCopy([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, filter]);

  useEffect(() => {
    if (clearFilters) {
      setFilterValue([]);
    }
  }, [clearFilters]);

  useEffect(() => {
    setSearching(loading);
  }, [loading]);


  const handleChangeFilterValue = async (items: any, reason: AutocompleteChangeReason) => {
    if (filter) {
      switch (reason) {
        case 'createOption':
          break;
        case 'selectOption':
          filterItem.values = items.length ? [ ...items.map((value: any) => value[filter.value] !== null ? value[filter.value] : null) ] : [];
          setFilterGroup([...filterGroup]);
          const filterToAdd: AudienceFilter = {
            member: filter.value,
            operator: 'equals',
            values: [ ...items.map((value: any) => typeof value === 'string' ? value : value[filter.value] !== null ? value[filter.value] : null)]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
          setSearchValue('');
          setFilterValue(items);
          setFilterOptions([...filterOptionsCopy]);
          break;
        case 'removeOption':
          const previousValues = filterItem.values;
          setSearchValue('');
          if (!items.length) {
            filterItem.values = [];
            setFilterValue([]);
            setFilterGroup([...filterGroup]);
            handleRemoveAudienceFilter(
              { member: filter.value, operator: 'equals', values: previousValues }, audienceFilters, setAudienceFilters
            );
          } else {
            const newValues = [...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])];
            filterItem.values = [...items.map((value: any) => typeof value === 'string' ? value : value[filter.value])];
            setFilterGroup([...filterGroup]);
            setFilterValue(items);
            handleRemoveFilterValue(
              { member: filter.value, operator: 'equals', values: previousValues }, newValues, audienceFilters, setAudienceFilters
            );
          }
          setFilterOptions([...filterOptionsCopy]);
          break;
        case 'clear':
          const prevValues = filterItem.values;
          if (prevValues.length) {
            filterItem.values = [];
            setFilterGroup([...filterGroup]);
            setSearchValue('');
            setFilterValue([]);
            handleRemoveAudienceFilter(
              { member: filter.value, operator: 'equals', values: prevValues }, audienceFilters, setAudienceFilters
            );
          }
          setFilterOptions([...filterOptionsCopy]);
          break;
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const getLabel = (option: any): string => {
    if (typeof option === 'string') {
      return option;
    }
    if (option[filter.value]) {
      return option[filter.value];
    }
    return 'Is unknown';
  };


  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='multi-select-popover'>
          <Box>
            <FilterChips
              minWidth={240}
              width={chipsWrapperWidth}
              filterValue={filterValue}
              filterOptions={true}
              filter={filter}
              getLabel={getLabel}
              handleChangeFilterValue={handleChangeFilterValue}
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
            />
            <Autocomplete
              multiple
              freeSolo
              fullWidth
              disableCloseOnSelect
              open={isDropdownOpen}
              value={filterValue}
              options={
                searching ? [{}] :
                  searchError ? [{}] :
                    filterSearched && !filterOptions.length ? [{}] :
                      filterOptions
              }
              id={`filter-${filter.value}-${Math.random()}`}
              size='small'
              inputValue={searchValue}
              getOptionLabel={(item: any) => item[filter.value] ? `${item[filter.value]}` : ''}
              renderOption={(props: any, item: any, { selected }) => {
                if (searching) {
                  return <SearchFilterIndicator size='regular' />;
                }
                if (searchError) {
                  return <LoadingFilterError />;
                }
                if (filterSearched && !filterOptions.length) {
                  return <EmptyFilter />;
                }
                return (
                  <Box
                    className='u-flex u-flex-align-center filter-checkbox-wrapper'
                    key={item[filter.value]}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          { ...props}
                          checked={selected}
                          classes={{
                            root: 'checkbox-root'
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`${item[filter.value] ? item[filter.value] : 'Is unknown'}`}
                      name={item[filter.value] || ''}
                      value={item[filter.value]}
                    />
                  </Box>
                );
              }}
              classes={{
                inputRoot: !!anchorEl && !!filterOptions.length ? 'multi-select-input' : 'multi-select-input no-bottom-radius',
                listbox: 'multi-select-paper'
              }}
              filterOptions={(item: any) => item}
              isOptionEqualToValue={(option: any, value: any) => option[filter.value] === value[filter.value]}
              onChange={(event: React.ChangeEvent<{}>, items: any, reason: AutocompleteChangeReason) => handleChangeFilterValue(items, reason)}
              renderTags={() => null}
              renderInput={(params: AutocompleteRenderInputParams) => (
                isDropdownOpen ? (
                  <TextField
                    {...params}
                    sx={{ visibility: 'hidden', height: '0px' }}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        visibility: 'hidden',
                        height: '0px',
                        padding: '0px',
                      }
                    }}
                  />
                ) : null
              )}
              PopperComponent={useCallback((props: PopperProps) => {
                return <Popper {...props}  className='multi-select-popper' placement='bottom-start' />;
              }, [])
              }
            />
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default MultiSelectFilter;

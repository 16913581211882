import type { NextPage } from 'next';
import { useRef, useState, useEffect } from 'react';
import { Popover, Box, Divider, Typography, Button, Chip } from '@mui/material';
import TabsWrapper from '../../components/tabsWrapper/tabsWrapper';
import BasicFilter from '../basicFilter/basicFilter';
import TabPanel from '../../components/tabPanel/tabPanel';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { UnaryFilter, UnaryOperator } from '@cubejs-client/core';
import { Segment, TableColumn } from '../../types/Common.interfaces';
import { Filter, AudienceFilter, CombinedAudienceFilter, RelatedAudienceFilter } from '../../types/Cube.interfaces';
import classes from './pageFilters.module.scss';
import { useGlobalState } from '../../context/globalState';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { format, subDays, differenceInCalendarMonths, differenceInDays, subMonths } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid-pro';

interface Props {
  audienceFilters: AudienceFilter[];
  currentTab: string;
  clearFilters?: boolean;
  setFilterChanged?: (value: boolean) => void;
  setAudienceFilters: (value: AudienceFilter[]) => void;
  handleCombinedNumericFilter?: (
    filterName: string, value: CombinedAudienceFilter, filters: AudienceFilter[] | CombinedAudienceFilter[], setFilters: (value: any[]) => void
  ) => void;
  segment?: Segment | null;
  dateFilterChanged?: boolean;
  setDateFilterChanged?: (value: boolean) => void;
}

const PageFilters: NextPage<Props> = ({
  audienceFilters, currentTab, clearFilters, setFilterChanged, setAudienceFilters, dateFilterChanged,
  handleCombinedNumericFilter, setDateFilterChanged, segment
}) => {
  const { companyFilters, peopleFilters, peopleColumns, companyColumns, peopleDatabaseColumns, companyDatabaseColumns } = useGlobalState();
  const { handleRemoveFilterValue, handleRemoveAudienceFilter } = useBasicFiltersState();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [currentFilterTab, setCurrentFilterTab] = useState<string>('organizations');
  const buttonRef = useRef<any>();
  const iconRef = useRef<any>();

  useEffect(() => {
    if (currentTab) {
      setCurrentFilterTab(currentTab);
    }
  }, [currentTab]);

  const handleClosePopper = (event: MouseEvent | TouchEvent) => {
    if (buttonRef.current !== event.target && iconRef.current !== event.target) {
      setAnchorEl(null);
    }
  };

  const handleTabWrapper = (value: string) => {
    setCurrentFilterTab(value);
  };

  const handleDeleteFilterValue = (filter: AudienceFilter, label: string) => {
    if (setFilterChanged && audienceFilters) {
      const newValues = filter.values.filter((value: string) => value !== label);
      if (newValues.length) {
        handleRemoveFilterValue(
          filter, newValues, audienceFilters, setAudienceFilters
        );
      } else {
        handleRemoveAudienceFilter(
          filter, audienceFilters, setAudienceFilters
        );
      }
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  const renderFilterValue = (
    filter: AudienceFilter | UnaryFilter | CombinedAudienceFilter, index: number
  ): React.ReactElement[] | React.ReactElement => {
    if (filter.hasOwnProperty('member')) {
      if (filter.hasOwnProperty('values')) {
        if (filter['operator'] && filter['operator'] === 'afterDate') {
          const daysDifference = segment && !dateFilterChanged ? differenceInDays(new Date(segment.updatedAt), new Date(filter['values'][0])) : 0;
          let dateValue: string;
          const now = format(new Date(Date.now()), 'yyyy-MM-dd');
          if (daysDifference > 30) {
            const monthDifference = segment ? differenceInCalendarMonths(new Date(segment.updatedAt), new Date(filter['values'][0])) : 0;
            dateValue = format(subMonths(new Date(now), monthDifference), 'yyyy-MM-dd');
          } else if (daysDifference > 6) {
            dateValue = format(subDays(new Date(now), daysDifference), 'yyyy-MM-dd');
          } else {
            dateValue = filter['values'][0];
          }
          return (
            <Chip
              key={index}
              sx={{ margin: '5px 8px 5px 0', border: '1px solid #2F3E4A', borderRadius: '8px' }}
              color='primary'
              label={dateValue}
              variant='outlined'
              onDelete={() => {
                if (setDateFilterChanged) {
                  setDateFilterChanged(true);
                }
                handleDeleteFilterValue(filter as AudienceFilter, filter['values'][0]);
              }}
              deleteIcon={<ClearIcon sx={{ color: '#2F3E4A' }} />}
            />
          );
        } else {
          return (
            filter['values'].map((value: string, position: number) => (
              <Chip
                label={filterValue(value, filter['member'], filter['operator'])}
                key={`${index}-${position}`}
                sx={{ margin: '5px 8px 5px 0', border: '1px solid #2F3E4A', borderRadius: '8px' }}
                color='primary'
                variant='outlined'
                onDelete={() => handleDeleteFilterValue(filter as AudienceFilter, value)}
                deleteIcon={<ClearIcon sx={{ color: '#2F3E4A' }} />}
              />
            ))
          );
        }
      } else {
        return (
          <Chip
            label={filter['operator'] === 'set' ? 'Yes' : 'No'}
            key={index}
            sx={{ margin: '5px 8px 5px 0', border: '1px solid #2F3E4A', borderRadius: '8px' }}
            color='primary'
            variant='outlined'
            onDelete={() => {
              if (audienceFilters && setFilterChanged) {
                handleRemoveAudienceFilter(
                  { member: filter['member'], operator: filter['operator'] as UnaryOperator }, audienceFilters, setAudienceFilters
                );
                setFilterChanged(true);
                setTimeout(() => {
                  setFilterChanged(false);
                }, 100);
              }
            }}
            deleteIcon={<ClearIcon sx={{ color: '#2F3E4A' }} />}
          />
        );
      }
    } else if (filter.hasOwnProperty('or')) {
      return filter['or'].map((item: RelatedAudienceFilter, index: number) => {
        let filterName = '';
        const allValues: string[] = [];
        item.and.forEach((value: AudienceFilter) => {
          filterName = value.member || '';
          allValues.push(...value.values);
        });
        const value = Math.min(...allValues.map((item: string) => +item));
        return (
          <Chip
            label={value === 0 ? '0 - Not Reviewed' : `${value} Star`}
            key={`${index}-${value}`}
            sx={{ margin: '5px 8px 5px 0', border: '1px solid #2F3E4A', borderRadius: '8px' }}
            color='primary'
            variant='outlined'
            onDelete={() => {
              if (audienceFilters && setFilterChanged && handleCombinedNumericFilter) {
                const orValues = [...filter['or']];
                orValues.splice(index, 1);
                const newValues = { or: orValues };
                handleCombinedNumericFilter(
                  filterName, newValues, audienceFilters, setAudienceFilters
                );
                setFilterChanged(true);
                setTimeout(() => {
                  setFilterChanged(false);
                }, 100);
              }
            }}
            deleteIcon={<ClearIcon sx={{ color: '#2F3E4A' }} />}
          />
        );
      });
    } else {
      return <></>;
    }
  };

  const filterValue  = (value: string | null, columnName: string, operator?: string): string => {
    const columns: TableColumn[] = [...peopleDatabaseColumns, ...companyDatabaseColumns];
    const findColumn = columns.find((item: TableColumn) => item.columnName === columnName.split('.')[1]);
    if (findColumn) {
      if (findColumn.columnType === 'boolean') {
        return value === '1' ? 'True' : value === '0' ? 'False' : 'Is unknown';
      } else if (findColumn.columnType === 'number') {
        return operator && operator === 'gt' ? `> ${value}` : operator === 'lt' ? `< ${value}` : value ? value : 'Is unknown';
      } else if (findColumn.columnType === 'url') {
        return value ? value.split('-*-')[0] : 'Is unknown';
      } else {
        return value ? value : 'Is unknown';
      }
    } else {
      return value ? value : 'Is unknown';
    }
  };

  const findFilterName = (filter: AudienceFilter | UnaryFilter | CombinedAudienceFilter): string => {
    const columns: GridColDef[] = [...peopleColumns, ...companyColumns];
    const findColumn = columns.find((column: GridColDef) => {
      if (filter.hasOwnProperty('member')) {
        return column.field === filter['member'];
      } else if (filter.hasOwnProperty('or') && filter['or'][0]['and'][0]['member']) {
        return column.field === filter['or'][0]['and'][0].member;
      } else {
        return false;
      }
    });
    if (findColumn) {
      return findColumn.headerName || '';
    } else {
      return '';
    }
  };

  const handleRemoveEntityFilters = (entity: string) => {
    if (audienceFilters.length && setFilterChanged) {
      const remainingFilters = audienceFilters.filter((item: any) => (
        (item.member && item.member.split('.')[0] === entity.toUpperCase()) ||
        (item.hasOwnProperty('or') && item.or && item.or.length &&
          (
            (item.or[0].member && item.or[0].member.split('.')[0] === entity.toUpperCase()) ||
            (item.or[0].and && item.or[0].and.length && item.or[0].and[0].member && item.or[0].and[0].member.split('.')[0] === entity.toUpperCase())
          ))
      ));
      setAudienceFilters([...remainingFilters]);
      setFilterChanged(true);
      setTimeout(() => {
        setFilterChanged(false);
      }, 100);
    }
  };

  return (
    <>
      <Button
        className={
          `u-text-transform-none filters-button outlined-button ${audienceFilters && audienceFilters.length || anchorEl ? 'focused' : ''}`
        }
        variant='outlined'
        startIcon={<FilterListIcon ref={iconRef} />}
        onClick={(event: React.MouseEvent) => setAnchorEl(anchorEl ? null : buttonRef.current)}
        ref={buttonRef}
      >
        Filter
        {audienceFilters.length ? (
          <Box
            component='span'
            className='filter-badge u-w-16-px u-h-16-px u-flex u-flex-justify-center u-flex-align-center u-fw-500 u-ml-8 fs-body-small u-br-50'
          >
            {audienceFilters.length}
          </Box>
        ) : null}
      </Button>
      <Popover
        id='filters-popover'
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleClosePopper}
        classes={{
          root: 'popover-root'
        }}
      >
        <Box className={`${classes.filtersWrapper} shadow`}>
          <TabsWrapper
            currentTab={currentFilterTab}
            handleTabChange={handleTabWrapper}
            loading={false}
            companyTotalCount={0}
            peopleTotalCount={0}
            showRelation={false}
            modifyStyles={true}
            metrics={false}
          />
          <TabPanel value={currentFilterTab} index='organizations'>
            <Box className='u-flex u-flex-justify-start u-flex-wrap u-px-12 u-pt-12'>
              {companyFilters.filter((filter: Filter) => filter.isDisplayed).map((filter: Filter) => (
                <BasicFilter
                  filter={filter}
                  key={filter.value}
                  audienceFilters={audienceFilters || []}
                  setAudienceFilters={setAudienceFilters}
                  clearFilters={clearFilters}
                  table='company'
                  setFilterChanged={setFilterChanged ? setFilterChanged : (value: boolean) => {}}
                  handleCombinedNumericFilter={handleCombinedNumericFilter!}
                  segment={segment}
                  dateFilterChanged={dateFilterChanged}
                  setDateFilterChanged={setDateFilterChanged}
                />
              ))}
            </Box>
            <Divider className='u-mt-20 u-mb-12 u-mx-12' />
            <Box className='u-flex u-flex-align-center u-flex-space-between u-px-12 u-pt-16 u-pb-16 u-sticky applied-filters'>
              <Typography className='u-fw-500 fs-title-regular'>Organization Filters Applied</Typography>
              <Box>
                <Button
                  size='small'
                  className='details-button'
                  onClick={() => handleRemoveEntityFilters('tam')}
                >
                  Clear All Organization Filters
                </Button>
              </Box>
            </Box>
            {audienceFilters && audienceFilters.length ? (
              <Box className='u-pt-20 u-pb-12 u-px-12'>
                {audienceFilters
                  .filter((value: AudienceFilter) => (
                    (value.member && value.member.startsWith('COMPANY')) ||
                    (value.hasOwnProperty('or') && value['or'][0]['and'][0].member.startsWith('COMPANY'))
                  ))
                  .map((value: AudienceFilter, index: number) => (
                    <Box key={index}>
                      <Typography className='fs-body-medium u-pr-40'>{findFilterName(value)}</Typography>
                      {renderFilterValue(value, index)}
                    </Box>
                  ))}
              </Box>
            ) : null}
          </TabPanel>
          <TabPanel value={currentFilterTab} index='people'>
            <Box className='u-flex u-flex-justify-start u-flex-wrap u-px-12 u-pt-12'>
              {peopleFilters.filter((filter: Filter) => filter.isDisplayed).map((filter: Filter) => (
                <BasicFilter
                  filter={filter}
                  key={filter.value}
                  audienceFilters={audienceFilters || []}
                  setAudienceFilters={setAudienceFilters}
                  clearFilters={clearFilters}
                  table='tam'
                  setFilterChanged={setFilterChanged ? setFilterChanged : (value: boolean) => {}}
                  handleCombinedNumericFilter={handleCombinedNumericFilter!}
                  segment={segment}
                  dateFilterChanged={dateFilterChanged}
                  setDateFilterChanged={setDateFilterChanged}
                />
              ))}
            </Box>
            <Divider className='u-mt-20 u-mb-12 u-mx-12' />
            <Box className='u-flex u-flex-align-center u-flex-space-between u-px-12 u-pt-16 u-pb-16 u-sticky applied-filters'>
              <Typography className='u-fw-500 fs-title-regular'>People Filters Applied</Typography>
              <Box>
                <Button
                  size='small'
                  className='details-button'
                  onClick={() => handleRemoveEntityFilters('company')}
                >
                  Clear All People Filters
                </Button>
              </Box>
            </Box>
            {audienceFilters && audienceFilters.length ? (
              <Box className='u-pt-20 u-pb-12 u-px-12'>
                {audienceFilters
                  .filter((value: AudienceFilter) => (
                    (value.member && value.member.startsWith('TAM')) ||
                    (value.hasOwnProperty('or') && value['or'][0]['and'][0].member.startsWith('TAM'))
                  ))
                  .map((value: AudienceFilter, index: number) => (
                    <Box key={index}>
                      <Typography className='fs-body-medium u-pr-40'>{findFilterName(value)}</Typography>
                      {renderFilterValue(value, index)}
                    </Box>
                  ))}
              </Box>
            ) : null}
          </TabPanel>
        </Box>
      </Popover>
    </>
  );
};

export default PageFilters;

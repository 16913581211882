import type { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { Box, Checkbox, Popper, ClickAwayListener, FormControlLabel } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useBasicFiltersState } from '../../context/basicFiltersState';

interface Props {
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filter: Filter;
  clearFilters: boolean;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
  filterItem: AudienceFilter;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
}

const BooleanCheckboxFilter: NextPage<Props> = ({
  anchorEl, handleClose, filter, clearFilters, audienceFilters, setAudienceFilters, setFilterChanged, filterItem, setFilterGroup, filterGroup
}) => {
  const { handleAddFilter, handleRemoveAudienceFilter, handleRemoveFilterValue } = useBasicFiltersState();
  const [filterCheckedValue, setFilterCheckedValue] = useState<string[]>([]);
  const [options] = useState<{ label: string, value: string }[]>([
    { label: 'Yes', value: 'Yes'},
    { label: 'Unknown', value: 'Unknown'}]
  );

  useEffect(() => {
    if (anchorEl && filter && audienceFilters && audienceFilters.length) {
      const findFilter = audienceFilters.find((item: AudienceFilter) => item.member === filter.value);
      if (findFilter && findFilter.values) {
        const filterValues: string[] = [];
        findFilter.values.forEach((value: string | null) => {
          if (value) {
            filterValues.push(value);
          }
        });
        setFilterCheckedValue(filterValues);
      } else {
        setFilterCheckedValue([]);
      }
    } else {
      setFilterCheckedValue([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, anchorEl]);

  useEffect(() => {
    if (clearFilters) {
      setFilterCheckedValue([]);
      filterItem.values = [];
      setFilterGroup([...filterGroup]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters]);

  const filterChanged = (filterName: string, value: boolean) => {
    if (value) {
      setFilterCheckedValue(prevValue => ([...new Set([...prevValue, filterName])]));
      const filterToAdd: AudienceFilter = {
        member: filter.value,
        operator: 'equals',
        values: [ filterName ] as any
      };
      filterItem.values = [ ...filterItem.values, filterName ] as any;
      setFilterGroup([...filterGroup]);
      handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
    } else {
      if (filterCheckedValue.length > 1) {
        const newValues = [...filterCheckedValue.filter((value: string) => value !== filterName)];
        setFilterCheckedValue(newValues);
        handleRemoveFilterValue(
          { member: filter.value, operator: 'equals', values: filterItem.values }, newValues, audienceFilters, setAudienceFilters
        );
        filterItem.values = newValues as any;
        setFilterGroup([...filterGroup]);
      } else {
        setFilterCheckedValue([]);
        handleRemoveAudienceFilter(
          { member: filter.value, operator: 'equals', values: [...filterItem.values] },
          audienceFilters,
          setAudienceFilters
        );
        filterItem.values = [];
        setFilterGroup([...filterGroup]);
      }
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 20);
  };

  const checkValue = (item: { label: string, value: string }): boolean => {
    return !!filterCheckedValue.find((value: string) => value === item.value);
  };

  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='checkbox-multi-select'>
          <Box
            sx={{ width: '100%', minWidth: '160px' }}
            className='u-p-12'
          >
            {options.map((item: { label: string, value: string }, index: number) => (
              <Box key={index}>
                <FormControlLabel
                  className='checkbox-button'
                  checked={checkValue(item)}
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        filterChanged(event.target.value as string, event.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      classes={{
                        root: 'checkbox-root'
                      }}
                    />
                  }
                  label={item.label}
                  name={item.value}
                  value={item.value}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default BooleanCheckboxFilter;

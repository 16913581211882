import type { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { Box, Checkbox, Popper, ClickAwayListener, FormControlLabel } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { UnaryFilter, UnaryOperator } from '@cubejs-client/core';

interface Props {
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filter: Filter;
  clearFilters: boolean;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
  filterItem: AudienceFilter;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
}

const CheckboxMultiSelectFilter: NextPage<Props> = ({
  anchorEl, handleClose, filter, clearFilters, audienceFilters, setAudienceFilters, setFilterChanged, filterItem, setFilterGroup, filterGroup
}) => {
  const { handleAddFilter, handleRemoveAudienceFilter, handleRemoveFilterValue } = useBasicFiltersState();
  const [filterCheckedValue, setFilterCheckedValue] = useState<string[]>([]);
  const [options, setOptions] = useState<{ label: string, value: string }[]>([]);

  useEffect(() => {
    if (filter.columnType !== 'boolean') {
      setOptions([{ label: 'Yes', value: 'set' },{ label: 'No', value: 'notSet' }]);
    } else {
      if (filter.showNulls) {
        setOptions([{ label: 'True', value: '1'},{ label: 'False', value: '0'},{ label: 'Is unknown', value: 'null'}]);
      } else {
        setOptions([{ label: 'True', value: '1'},{ label: 'False', value: '0'}]);
      }
    }
  }, [filter]);

  useEffect(() => {
    if (anchorEl && filter && audienceFilters && audienceFilters.length) {
      if (filter.columnType !== 'boolean') {
        const findFilters = audienceFilters.filter((item: AudienceFilter) => item.member === filter.value);
        if (findFilters && findFilters.length) {
          setFilterCheckedValue(findFilters.map((filter: AudienceFilter) => filter.operator));
        }
      } else {
        const findFilters = audienceFilters.filter((item: AudienceFilter) => item.member === filter.value);
        if (findFilters && findFilters.length) {
          if (findFilters[0].values) {
            const filterValues: string[] = [];
            findFilters[0].values.forEach((value: string | null) => {
              if (value) {
                filterValues.push(value);
              } else {
                filterValues.push('null');
              }
            });
            setFilterCheckedValue(filterValues);
          } else {
            setFilterCheckedValue(['null']);
          }
        }
      }
    } else {
      setFilterCheckedValue([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, anchorEl]);

  useEffect(() => {
    if (clearFilters) {
      setFilterCheckedValue([]);
      filterItem.values = [];
      setFilterGroup([...filterGroup]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters]);

  const filterChanged = (filterName: string, value: boolean, label: string) => {
    if (value) {
      if (filter.columnType !== 'boolean') {
        const filterToAdd: UnaryFilter = {
          member: filter.value,
          operator: filterName as UnaryOperator,
        };
        if (!filterCheckedValue.length) {
          setFilterCheckedValue(prevValue => ([...new Set([...prevValue, filterName])]));
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        } else {
          setFilterCheckedValue(prevValue => ([...new Set([...prevValue, filterName])]));
          handleRemoveAudienceFilter(
            { member: filter.value, operator: filterName === 'set' ? 'notSet' : 'set' as UnaryOperator }, audienceFilters, setAudienceFilters
          );
        }
      } else {
        setFilterCheckedValue(prevValue => ([...new Set([...prevValue, filterName])]));
        const filterToAdd: AudienceFilter = {
          member: filter.value,
          operator: 'equals',
          values: [ filterName !== 'null' ? filterName : null ] as any
        };
        filterItem.values = [ ...filterItem.values, filterName !== 'null' ? filterName : null ] as any;
        setFilterGroup([...filterGroup]);
        handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
      }
    } else {
      if (filter.columnType !== 'boolean') {
        if (filterCheckedValue.length === 2) {
          setFilterCheckedValue(prevValue => ([...prevValue.filter((value: string) => value !== filterName)]));
          const filterToAdd: UnaryFilter = {
            member: filter.value,
            operator: filterName === 'set' ? 'notSet' : 'set' as UnaryOperator,
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        } else {
          setFilterCheckedValue(prevValue => ([...prevValue.filter((value: string) => value !== filterName)]));
          handleRemoveAudienceFilter(
            { member: filter.value, operator: filterName as UnaryOperator }, audienceFilters, setAudienceFilters
          );
        }
      } else {
        if (filterCheckedValue.length > 1) {
          setFilterCheckedValue(prevValue => ([...prevValue.filter((value: string) => value !== filterName)]));
          const itemValue = filterName !== 'null' ? filterName : null;
          const newValues = [...filterItem.values.filter((value: string | null) => {
            if (value === null && value !== itemValue) {
              return 'null';
            } else if (value === '0' && value !== itemValue) {
              return value;
            } else if (value === '1' && value !== itemValue) {
              return value;
            } else {
              return null;
            }
          }).map((value: string) => value === 'null' ? null : value)];
          handleRemoveFilterValue(
            { member: filter.value, operator: 'equals', values: filterItem.values }, newValues, audienceFilters, setAudienceFilters
          );
          filterItem.values = newValues as any;
          setFilterGroup([...filterGroup]);
        } else {
          setFilterCheckedValue([]);
          handleRemoveAudienceFilter(
            { member: filter.value, operator: 'equals', values: [...filterItem.values] },
            audienceFilters,
            setAudienceFilters
          );
          filterItem.values = [];
          setFilterGroup([...filterGroup]);
        }
      }
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 100);
  };

  const checkValue = (item: { label: string, value: string }): boolean => {
    return !!filterCheckedValue.find((value: string) => value === item.value);
  };

  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='checkbox-multi-select'>
          <Box
            sx={{ width: '100%', minWidth: '160px' }}
            className='u-p-12'
          >
            {options.map((item: { label: string, value: string }, index: number) => (
              <Box key={index}>
                <FormControlLabel
                  className='checkbox-button'
                  checked={checkValue(item)}
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        filterChanged(event.target.value as string, event.target.checked, item.label);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      classes={{
                        root: 'checkbox-root'
                      }}
                    />
                  }
                  label={item.label}
                  name={item.value}
                  value={item.value}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default CheckboxMultiSelectFilter;

import type { NextPage } from 'next';
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import classes from './basicFilter.module.scss';
import { AudienceFilter, CombinedAudienceFilter, Filter } from '../../types/Cube.interfaces';
import { Segment } from '../../types/Common.interfaces';
import { Query, ResultSet } from '@cubejs-client/core';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MultiSelectFilter from '../multiSelectFilter/multiSelectFilter';
import SearchMultiSelectFilter from '../searchMultiSelectFilter/searchMultiSelectFilter';
import CheckboxMultiSelectFilter from '../checkboxMultiSelectFilter/checkboxMultiSelectFilter';
import SearchPartialMultiSelectFilter from '../searchPartialMultiSelectFilter/searchPartialMultiSelectFilter';
import SearchMultiSelectAllOperatorsFilter from '../searchMultiSelectAllOperatorsFilter/searchMultiSelectAllOperatorsFilter';
import DateFilter from '../dateFilter/dateFilter';
import SearchMultiSelectPrefixFilter from '../searchMultiSelectPrefixFilter/searchMultiSelectPrefixFilter';
import SearchMultiSelectPrefixTechnologyFilter from '../searchMultiSelectPrefixTechnologyFilter/searchMultiSelectPrefixTechnologyFilter';
import SearchMultiSelectContainsPrefixFilter from '../searchMultiSelectContainsPrefixFilter/searchMultiSelectContainsPrefixFilter';
import PredefinedCheckboxFilter from '../predefinedCheckboxFilter/predefinedCheckboxFilter';
import PredefinedCheckboxCombineFilter from '../predefinedCheckboxCombineFilter/predefinedCheckboxCombineFilter';
import PredefinedCheckboxEqualsFilter from '../predefinedCheckboxEqualsFilter/predefinedCheckboxEqualsFilter';
import NumericalFilter from '../numericalFilter/numericalFilter';
import NumericalSelectFilter from '../numericalSelectFilter/numericalSelectFilter';
import BooleanCheckboxFilter from '../booleanCheckboxFilter/booleanCheckboxFilter';
import PersonaFilter from '../personaFilter/personaFilter';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';

interface Props {
  filter: Filter;
  audienceFilters: (AudienceFilter | CombinedAudienceFilter)[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  clearFilters?: boolean;
  table: string;
  setFilterChanged: (value: boolean) => void;
  handleCombinedNumericFilter: (
    filterName: string, currentFilter: CombinedAudienceFilter, currentFilters: AudienceFilter[] | CombinedAudienceFilter[],
    setFilters: (value: any[]) => void
  ) => void;
  segment?: Segment | null;
  dateFilterChanged?: boolean;
  setDateFilterChanged?: (value: boolean) => void;
}

const BasicFilters: NextPage<Props> = ({
  filter, audienceFilters, setAudienceFilters, clearFilters, table, setFilterChanged, handleCombinedNumericFilter, segment,
  dateFilterChanged, setDateFilterChanged
}) => {
  const { handleRemoveAudienceFilter, modifyFilters, modifyJobTitleFilter } = useBasicFiltersState();
  const [filterHasValue, setFilterHasValue] = useState<boolean>(false);
  const [filterGroup, setFilterGroup] = useState<AudienceFilter[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [suggestionsCopy, setSuggestionsCopy] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [technologyFilters] = useState<string[]>([
    'crm_tech_org', 'marketing_automation_tech_org', 'abm_tech_org', 'sales_automation_tech_org', 'analytics_tech_org', 'cms_tech_org',
    'conversation_intelligence_tech_org', 'cloud_security_tech_org', 'cloud_provider_tech_org', 'email_hosting_tech_org', 'email_security_tech_org',
    'application_security_tech_org', 'development_tech_org', 'e_commerce_platform_tech_org',
  ]);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [filterSearched, setFilterSearched] = useState<boolean>(false);
  const [numericalSelectFilterValues, setNumericalSelectFilterValues] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const filterRef = useRef<any>();
  const addedFilterRef = useRef<any>();
  const iconRef = useRef<any>();
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (audienceFilters.length) {
      const shouldModify = filter.type === 'predefined checkbox combine';
      const foundFilters = audienceFilters.filter((item: AudienceFilter | CombinedAudienceFilter) => (
        item.hasOwnProperty('member') && item['member'].split('.')[1] === filter.value.split('.')[1]
      ));
      if (foundFilters.length) {
        if (shouldModify) {
          setFilterGroup([{
            member: filter.value,
            operator: 'contains',
            values: [...foundFilters.map((item: AudienceFilter| CombinedAudienceFilter) => item['values'][0])]
          }]);
        } else {
          setFilterGroup(foundFilters.map((item: AudienceFilter | CombinedAudienceFilter) => ({ ...item, member: filter.value } as AudienceFilter)));
        }
      } else {
        setFilterGroup([{ member: filter.value, operator: 'equals', values: [] }]);
      }
    } else {
      setFilterGroup([{ member: filter.value, operator: 'equals', values: [] }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (anchorEl) {
      if (audienceFilters.length) {
        const shouldModify = filter.type === 'predefined checkbox combine';
        const modifiedFilters = modifyFilters(audienceFilters as AudienceFilter[], shouldModify);
        setAudienceFilters([...modifiedFilters]);
        const foundFilters = modifiedFilters.filter((item: AudienceFilter) => (
          item.member && filter.value && item.member.split('.')[1] === filter.value.split('.')[1]
        ));
        if (foundFilters.length) {
          if (shouldModify) {
            setFilterGroup([{
              member: filter.value,
              operator: 'contains',
              values: [...foundFilters.map((item: AudienceFilter| CombinedAudienceFilter) => item['values'][0])]
            }]);
          } else {
            setFilterGroup(foundFilters.map((item: AudienceFilter) => ({ ...item, member: filter.value })));
          }
        } else {
          setFilterGroup([{ member: filter.value, operator: 'equals', values: [] }]);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  useEffect(() => {
    if (audienceFilters.length) {
      const findFilter = audienceFilters.find((item: any) => {
        // Find filter by looking for and comparing its name with the value of the key "member", inside the object in array, nested into "or" or both or and "and";
        return (item.member && item.member.split('.')[1] === filter.value.split('.')[1]) ||
        (!!item.or && item.or.length &&
          (
            (item.or[0].member && item.or[0].member.split('.')[1] === filter.value.split('.')[1]) ||
            (
              item.or[0].and && item.or[0].and.length &&
              item.or[0].and[0].member && item.or[0].and[0].member.split('.')[1] === filter.value.split('.')[1])
          ));
      });
      if (filter.type === 'numerical select') {
        if (findFilter) {
          const values: number[] = [];
          (findFilter as CombinedAudienceFilter).or.forEach((value: any) => {
            const minValues: string[] = [];
            value.and.forEach((item: any) => {
              minValues.push(...item.values);
            });
            const minValue = Math.min(...minValues.map((val: string) => +val));
            values.push(minValue);
          });
          setNumericalSelectFilterValues(values.map((item: number) => String(item)));
        } else {
          setNumericalSelectFilterValues([]);
        }
      }
      setFilterHasValue(!!findFilter);
    } else {
      setFilterHasValue(false);
    }
  }, [audienceFilters, filter]);

  useEffect(() => {
    if (clearFilters) {
      setFilterGroup([{ member: filter.value, operator: 'equals', values: [] }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters]);

  const loadFilterOptions = async (addFilters: boolean) => {
    try {
      setSearchError(false);
      setLoading(true);
      const columnName = filter.value.split('.')[1];
      const query: Query = {
        measures: columnName !== 'caregiver_states_org' ? [
          filter.searchColumn ? `${filter.searchColumn.split('.')[0]}.count` : `${filter.value.split('.')[0]}.count`
        ] : [],
        dimensions: [
          filter.searchColumn ? filter.searchColumn : filter.value
        ],
        filters: addFilters && audienceFilters.length > 0 ? [
          ...modifyJobTitleFilter(audienceFilters)
            .filter((item: AudienceFilter | CombinedAudienceFilter) => {
              const splitValue = item.hasOwnProperty('member') && item['member'].split('.')[1];
              const forwarderFilterSplitValue = filter.value.split('.')[1];
              return splitValue && splitValue !== forwarderFilterSplitValue && !item.hasOwnProperty('or');
            })
        ] : [],
        limit: columnName === 'name' || filter.type.startsWith('preload') ? 100 : 1000,
      };
      const resultSet: ResultSet<any> = await cubejsApiWrapper(query, { mutexKey: columnName });
      if (filter.searchColumn) {
        setSuggestions(
          resultSet.tablePivot()
            .filter((item: any) => filter.showNulls ? true : item[filter.searchColumn ? filter.searchColumn : filter.value])
            .map((item: any) => ({ [filter.value as string]: item[filter.searchColumn ? filter.searchColumn : filter.value] }))
        );
        setSuggestionsCopy(
          resultSet.tablePivot()
            .filter((item: any) => filter.showNulls ? true : item[filter.searchColumn ? filter.searchColumn : filter.value])
            .map((item: any) => ({ [filter.value as string]: item[filter.searchColumn ? filter.searchColumn : filter.value] }))
        );
      } else {
        setSuggestions(
          resultSet.tablePivot().filter((item: any) => filter.showNulls ? true : item[filter.value])
        );
        setSuggestionsCopy(resultSet.tablePivot().filter((item: any) => filter.showNulls ? true : item[filter.value]));
      }
      setFilterSearched(true);
    } catch (error) {
      setSuggestions([]);
      setSuggestionsCopy([]);
      setSearchError(true);
      setFilterSearched(true);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFilter = (event: React.MouseEvent) => {
    const openedFilter = !anchorEl;
    setAnchorEl(anchorEl ? null : filterRef.current);
    if (openedFilter) {
      if (
        (filter.type === 'search multi select') || (filter.type.startsWith('preload'))
      ) {
        loadFilterOptions(true);
      } else if (
        (
          filter.type === 'multi select' || filter.type === 'predefined checkbox' ||
          filter.type === 'predefined checkbox combine' || filter.type === 'predefined checkbox equals' ||
          technologyFilters.includes(filter.value.split('.')[1])
        ) && !suggestions.length
      ) {
        loadFilterOptions(false);
      }
    }
  };

  const handleAddNewFilter = () => {
    setFilterGroup(prevState => ([ ...prevState, { member: filter.value, operator: 'equals', values: [] }]));
  };

  const handleRemoveFilter = (position: number) => {
    const foundFilter = filterGroup.find((item: AudienceFilter, index: number) => index === position);
    if (foundFilter) {
      setFilterGroup([...filterGroup.filter((item: AudienceFilter, index: number) => index !== position)]);
      if (foundFilter.values && foundFilter.values.length) {
        handleRemoveAudienceFilter(foundFilter, audienceFilters as AudienceFilter[], setAudienceFilters);
      }
    }
  };

  const handleCloseFilter = (event: MouseEvent | TouchEvent) => {
    if (event.target !== filterRef.current && event.target !== iconRef.current && event.target !== addedFilterRef.current) {
      setAnchorEl(null);
    }
  };

  const returnFilter = (type: string, filterItem: AudienceFilter, index: number) => {
    switch (type) {
      case 'multi select':
        return (
          <MultiSelectFilter
            key={`${filter.value}-${index}`}
            options={suggestions}
            filter={filter}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filterItem={filterItem}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            loading={loading}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            searchError={searchError}
            filterSearched={filterSearched}
          />
        );
      case 'search multi select':
        return (
          <SearchMultiSelectFilter
            key={`${filter.value}-${index}`}
            options={suggestions}
            optionsCopy={suggestionsCopy}
            setOptions={setSuggestions}
            filter={filter}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filterItem={filterItem}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            clearFilters={!!clearFilters}
            table={table}
            audienceFilters={audienceFilters as AudienceFilter[]}
            loading={loading}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
          />
        );
      case 'checkbox multi select':
        return (
          <CheckboxMultiSelectFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
          />
        );
      case 'search partial multi select':
        return (
          <SearchPartialMultiSelectFilter
            key={`${filter.value}-${index}`}
            filter={filter}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            table={table}
            handleAddNewFilter={handleAddNewFilter}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            handleRemoveFilter={handleRemoveFilter}
            clearFilters={!!clearFilters}
            setFilterChanged={setFilterChanged}
            partialOperators={false}
          />
        );
      case 'search partial multi select contains':
        return (
          <SearchPartialMultiSelectFilter
            key={`${filter.value}-${index}`}
            filter={filter}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            table={table}
            handleAddNewFilter={handleAddNewFilter}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            handleRemoveFilter={handleRemoveFilter}
            clearFilters={!!clearFilters}
            setFilterChanged={setFilterChanged}
            partialOperators={true}
          />
        );
      case 'search multi select all operators':
        return (
          <SearchMultiSelectAllOperatorsFilter
            key={`${filter.value}-${index}`}
            options={suggestions}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            table={table}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
          />
        );
      case 'date':
        return (
          <DateFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            clearFilters={!!clearFilters}
            setFilterChanged={setFilterChanged}
            segment={segment}
            dateFilterChanged={dateFilterChanged}
            setDateFilterChanged={setDateFilterChanged}
          />
        );
      case 'search multi select prefix':
      case 'preload search multi select prefix':
        return (
          <SearchMultiSelectPrefixFilter
            key={`${filter.value}-${index}`}
            options={suggestions}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            table={table}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            loading={loading}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
          />
        );
      case 'search multi select prefix technology':
        return (
          <SearchMultiSelectPrefixTechnologyFilter
            key={`${filter.value}-${index}`}
            options={suggestions}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            table={table}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            loading={loading}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
          />
        );
      case 'search multi select contains prefix':
      case 'preload search multi select contains prefix':
        return (
          <SearchMultiSelectContainsPrefixFilter
            key={`${filter.value}-${index}`}
            options={suggestions}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            table={table}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
          />
        );
      case 'predefined checkbox':
        return (
          <PredefinedCheckboxFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            options={suggestions}
            filter={filter}
            filterItem={filterItem}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            searchError={searchError}
            filterSearched={filterSearched}
          />
        );
      case 'predefined checkbox equals':
        return (
          <PredefinedCheckboxEqualsFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            options={suggestions}
            filter={filter}
            filterItem={filterItem}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            searchError={searchError}
            filterSearched={filterSearched}
          />
        );
      case 'predefined checkbox combine':
        return (
          <PredefinedCheckboxCombineFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            options={suggestions}
            filter={filter}
            filterItem={filterItem}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            searchError={searchError}
            filterSearched={filterSearched}
          />
        );
      case 'numerical':
        return (
          <NumericalFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
          />
        );
      case 'numerical select':
        return (
          <NumericalSelectFilter
            key={`${filter.value}-${index}`}
            filter={filter}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filterItem={filterItem}
            numericalFilterValues={numericalSelectFilterValues}
            setNumericalFilterValues={setNumericalSelectFilterValues}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            handleCombinedNumericFilter={handleCombinedNumericFilter}
          />
        );
      case 'boolean checkbox':
        return (
          <BooleanCheckboxFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            clearFilters={!!clearFilters}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
          />
        );
      case 'persona':
        return (
          <PersonaFilter
            key={`${filter.value}-${index}`}
            anchorEl={anchorEl}
            handleClose={handleCloseFilter}
            filter={filter}
            filterItem={filterItem}
            audienceFilters={audienceFilters as AudienceFilter[]}
            setAudienceFilters={setAudienceFilters}
            setFilterChanged={setFilterChanged}
            setFilterGroup={setFilterGroup}
            filterGroup={filterGroup}
          />
        );
      default: return null;
    }
  };

  return (
    <Box className={classes.filtersWrapper}>
      <Box>
        <Button
          variant='contained'
          className={`filter-button filter-button-regular ${filter.isCustom ? 'custom-filter-button' : ''}`}
          color='primary'
          onClick={(event: React.MouseEvent) => handleToggleFilter(event)}
          ref={filterRef}
          sx={anchorEl ? !filter.isCustom ?
            { background: '#E8DEF8 !important' } :
            { background: '#ffb54e !important', borderColor: '#79747E !important' } : {}
          }
          disableElevation
          endIcon={<ArrowDropDownIcon ref={iconRef} sx={{ color: '#232f3e' }} />}
          startIcon={filterHasValue ? <CheckIcon ref={addedFilterRef} /> : undefined}
        >
          {filter.type === 'checkbox multi select' && filter.columnType !== 'boolean' ? `Has ${filter.title}` : filter.title}
        </Button>
      </Box>
      <Box>
        {filterGroup.map((item: AudienceFilter, index: number) => (
          returnFilter(filter.type, item, index)
        ))}
      </Box>
    </Box>
  );
};

export default BasicFilters;

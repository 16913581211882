import type { NextPage } from 'next';
import { useState, useEffect } from 'react';
import { Box, ClickAwayListener, Popper, Autocomplete, TextField, AutocompleteRenderInputParams, MenuItem } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { subDays, format, subMonths, parseISO, differenceInDays, addDays, differenceInCalendarMonths } from 'date-fns';
import { Segment } from '../../types/Common.interfaces';

interface Props {
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filter: Filter;
  filterItem: AudienceFilter;
  filterGroup: AudienceFilter[];
  setFilterGroup: (value: AudienceFilter[]) => void;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  clearFilters: boolean;
  setFilterChanged: (value: boolean) => void;
  segment?: Segment | null;
  dateFilterChanged?: boolean;
  setDateFilterChanged?: (value: boolean) => void;
}

const DateFilter: NextPage<Props> = ({
  anchorEl, handleClose, filter, filterItem, filterGroup, setFilterGroup, audienceFilters, dateFilterChanged,
  setAudienceFilters, clearFilters, setFilterChanged, segment, setDateFilterChanged,
}) => {
  const { handleAddDateFilter, handleRemoveAudienceFilter } = useBasicFiltersState();
  const [filterValue, setFilterValue] = useState<{ label: string, value: number, metric: string } | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [options] = useState<{ label: string, value: number, metric: string }[]>([
    { label: 'Last 7 Days', value: 7, metric: 'days' },
    { label: 'Last 30 Days', value: 30, metric: 'days' },
    { label: 'Last 3 Months', value: 3, metric: 'months' },
    { label: 'Last 6 Months', value: 6, metric: 'months' },
    { label: 'Last 12 Months', value: 12, metric: 'months' }
  ]);

  useEffect(() => {
    if (filterItem && filterItem.values && filterItem.values.length) {
      const optionsValue: { value: string, label: string }[] = options.map((item: { label: string, value: number, metric: string }) => {
        if (item.metric === 'days') {
          return { value: format(subDays(Date.now(), item.value), 'yyyy-MM-dd'), label: item.label };
        } else if (item.metric === 'months') {
          return { value: format(subMonths(Date.now(), item.value), 'yyyy-MM-dd'), label: item.label };
        } else {
          return { value: format(subDays(Date.now(), item.value), 'yyyy-MM-dd'), label: item.label };
        }
      });
      let dateValue: string;
      const now = format(new Date(Date.now()), 'yyyy-MM-dd');
      if (segment && !dateFilterChanged) {
        if (segment.updatedAt) {
          const updatedDate = format(parseISO(segment.updatedAt), 'yyyy-MM-dd');
          const daysDifference = differenceInDays(new Date(now), new Date(updatedDate));
          dateValue = format(addDays(new Date(filterItem.values[0]), daysDifference), 'yyyy-MM-dd');
        } else {
          dateValue = filterItem.values[0];
        }
      } else {
        dateValue = filterItem.values[0];
      }
      const daysDifference = differenceInDays(new Date(now), new Date(dateValue));
      if (daysDifference > 30) {
        const monthDifference = differenceInCalendarMonths(new Date(now), new Date(dateValue));
        const findValue = options.find((item: { label: string, value: number, metric: string }) => item.value === monthDifference);
        setSearchValue(findValue ? findValue.label : '');
        findValue && setFilterValue(findValue);
      } else {
        const findValue = optionsValue.find((item: { value: string, label: string }) => item.value === dateValue);
        setSearchValue(findValue ? findValue.label : '');
        const findFilterValue = options.find((option: { value: number, label: string, metric: string }) => option.label === findValue?.label);
        findFilterValue && setFilterValue(findFilterValue);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment]);

  useEffect(() => {
    if (clearFilters) {
      setSearchValue('');
      setFilterValue(null);
    }
  }, [clearFilters]);

  const handleValueChanged = (item: { label: string, value: number, metric: string }) => {
    if (item) {
      let date;
      if (item.metric === 'days') {
        date = subDays(Date.now(), item.value);
      } else if (item.metric === 'months') {
        date = subMonths(Date.now(), item.value);
      } else {
        date = subDays(Date.now(), item.value);
      }
      const formatedDate = format(date, 'yyyy-MM-dd');
      filterItem.values = [formatedDate];
      setFilterGroup([...filterGroup]);
      const filterToAdd: AudienceFilter = {
        member: filter.value,
        operator: 'afterDate',
        values: [formatedDate]
      };
      handleAddDateFilter(filterToAdd, audienceFilters, setAudienceFilters);
      setSearchValue(item.label);
      setFilterValue(item);
    } else {
      if (segment && setDateFilterChanged) {
        setDateFilterChanged(true);
      }
      const filterToDelete: AudienceFilter = {
        member: filter.value,
        operator: 'afterDate',
        values: filterItem.values
      };
      filterItem.values = [];
      setSearchValue('');
      setFilterValue(null);
      handleRemoveAudienceFilter(filterToDelete, audienceFilters, setAudienceFilters);
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 20);
  };

  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box sx={{ minWidth: '240px' }}>
          <Autocomplete
            disablePortal
            fullWidth
            open={!!anchorEl}
            value={filterValue ? filterValue : null}
            options={options}
            id={`filter-${filter.value}-${Math.random()}`}
            size='small'
            inputValue={searchValue}
            getOptionLabel={(option: any) => option.label}
            classes={{
              inputRoot: 'multi-select-input',
              listbox: 'multi-select-paper',
            }}
            renderOption={(props: any, item: any, { selected }) => (
              <MenuItem
                {...props}
                key={item.value}
                sx={{ backgroundColor: selected ? '#A0A5AC !important' : '' }}
              >
                {item.label}
              </MenuItem>
            )}
            onChange={(event: React.ChangeEvent<{}>, item: any) => handleValueChanged(item)}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                color='primary'
                placeholder={filter.value.includes('created') ? 'Created in last...' : 'Updated in last...'}
                fullWidth
                className='full-width date-filter-input'
                InputProps={{
                  ...params.InputProps,
                  style: {
                    height: '48px',
                  }
                }}
              />
            )}
          />
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default DateFilter;

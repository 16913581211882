import type { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { Box, Checkbox, Popper, ClickAwayListener, FormControlLabel, Skeleton } from '@mui/material';
import { AudienceFilter, Filter } from '../../types/Cube.interfaces';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { useCubeContext } from '../../context/cubeState';
import { UnaryOperator, BinaryFilter, ResultSet, Query } from '@cubejs-client/core';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';

interface Props {
  anchorEl: any;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  filter: Filter;
  audienceFilters: AudienceFilter[];
  setAudienceFilters: (value: AudienceFilter[]) => void;
  setFilterChanged: (value: boolean) => void;
  filterItem: AudienceFilter;
  setFilterGroup: (value: AudienceFilter[]) => void;
  filterGroup: AudienceFilter[];
}

const PersonaFilter: NextPage<Props> = ({
  anchorEl, handleClose, filter, audienceFilters, setAudienceFilters, setFilterChanged, filterItem,
  setFilterGroup, filterGroup
}) => {
  const { cubejsApi } = useCubeContext();
  const { handleAddFilter, handleRemoveAudienceFilter, handleRemoveFilterValue } = useBasicFiltersState();
  const [filterCheckedValue, setFilterCheckedValue] = useState<(string | null)[]>([]);
  const [filterOptions, setFilterOptions] = useState<{ label: string, values: string[] }[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    if (anchorEl && cubejsApi) {
      (async function iife () {
        try {
          setSearching(true);
          const query: Query = {
            dimensions: ['PERSONA.persona_type', 'PERSONA.per_persona', 'PERSONA.display_order'],
            order: [['PERSONA.display_order', 'asc']],
          };
          const resultSet: ResultSet<any> = await cubejsApiWrapper(query);
          const data: { label: string, values: string[], displayOrder: number }[] = [];
          resultSet.tablePivot().forEach((item: { [key: string]: string | number | boolean }) => {
            const parentLabel = item['PERSONA.persona_type'] as string;
            const childValue = item['PERSONA.per_persona'] as string;
            const displayOrder = item['PERSONA.display_order'] as number;
            const findParent = data.find((value: { label: string, values: string[], displayOrder: number }) => value.label === parentLabel);
            if (findParent) {
              findParent.values.push(item['PERSONA.per_persona'] as string);
            } else {
              data.push({ label: parentLabel, values: [childValue], displayOrder: displayOrder });
            }
          });
          setFilterOptions(data);
        } catch (error) {
          setFilterOptions([]);
        } finally {
          setSearching(false);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl, cubejsApi]);


  useEffect(() => {
    if (anchorEl && filter && audienceFilters && audienceFilters.length) {
      const findFilters = audienceFilters.filter((item: AudienceFilter) => item.member && item.member.split('.')[1] === filter.value.split('.')[1]);
      if (findFilters && findFilters.length) {
        const values: (string | null)[] = [];
        findFilters.forEach((filter: AudienceFilter) => {
          filter.values.forEach((value: string) => {
            values.push(value ? value : null);
          });
        });
        setFilterCheckedValue(values);
      }
    } else {
      setFilterCheckedValue([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, anchorEl]);

  const filterChanged = (filterName: UnaryOperator, checked: boolean, filterValue: string) => {
    if (checked) {
      const isNullAddedIndex = filterItem.values.findIndex((value: string | null) => value === null);
      if (isNullAddedIndex > -1) {
        if (filterCheckedValue.length === 1) {
          const filterToRemove: BinaryFilter = {
            member: filter.value,
            operator: 'equals',
            values: [...filterItem.values]
          };
          handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [...filterItem.values, filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        } else {
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [...filterItem.values, filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        }
      } else {
        if (filterValue) {
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        } else {
          const filterToRemove: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: [...filterItem.values]
          };
          handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
          const filterToAdd: BinaryFilter = {
            member: filter.value,
            operator: filterItem.values.length ? 'contains' : 'equals',
            values: [...filterItem.values, filterValue]
          };
          handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
        }
      }
      filterItem.values = [ ...new Set([ ...filterItem.values, filterValue])];
      setFilterGroup([...filterGroup]);
      setFilterCheckedValue(prevValue => ([...new Set([...prevValue, filterName ? filterName : filterValue])]));
    } else {
      if (filterItem.values.length === 1) {
        setFilterCheckedValue([]);
        const filterToRemove: BinaryFilter = {
          member: filter.value,
          operator: filterItem.values[0] ? 'contains' : 'equals',
          values: [...filterItem.values]
        };
        handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
        filterItem.values = [];
        setFilterGroup([...filterGroup]);
      } else if (filterItem.values.length === 2) {
        const isNullAddedIndex = filterItem.values.findIndex((value: string | null) => value === null);
        if (isNullAddedIndex > -1) {
          if (!filterValue) {
            const previousValues = filterItem.values;
            const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
            setFilterCheckedValue([...newValues]);
            const filterToRemove: BinaryFilter = {
              member: filter.value,
              operator: 'contains',
              values: previousValues
            };
            handleRemoveFilterValue(filterToRemove, newValues, audienceFilters, setAudienceFilters);
          } else {
            const previousValues = filterItem.values;
            const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
            setFilterCheckedValue([...newValues]);
            const filterToRemove: BinaryFilter = {
              member: filter.value,
              operator: 'contains',
              values: previousValues
            };
            handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
            const filterToAdd: BinaryFilter = {
              member: filter.value,
              operator: 'equals',
              values: [...previousValues.filter((value: string) => value !== filterValue)]
            };
            handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
          }
        } else {
          const previousValues = filterItem.values;
          const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
          setFilterCheckedValue([...newValues]);
          const filterToRemove: BinaryFilter = {
            member: filter.value,
            operator: 'contains',
            values: previousValues
          };
          handleRemoveFilterValue(filterToRemove, newValues, audienceFilters, setAudienceFilters);
        }
        filterItem.values = [...filterItem.values.filter((value: string) => value !== filterValue)];
        setFilterGroup([...filterGroup]);
      } else if (filterItem.values.length > 2) {
        const previousValues = filterItem.values;
        const newValues = filterCheckedValue.filter((value: string | null) => value !== filterValue);
        setFilterCheckedValue([...newValues]);
        const filterToRemove: BinaryFilter = {
          member: filter.value,
          operator: 'contains',
          values: previousValues
        };
        handleRemoveFilterValue(filterToRemove, newValues, audienceFilters, setAudienceFilters);
        filterItem.values = [...filterItem.values.filter((value: string) => value !== filterValue)];
        setFilterGroup([...filterGroup]);
      }
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 20);
  };

  const parentFilterChanges = (filterName: UnaryOperator, checked: boolean, filterValue: string) => {
    const findOption = filterOptions.find((item: { label: string, values: string[]}) => item.label === filterValue);
    const option = findOption ? findOption.values : [];
    if (checked) {
      if (filterCheckedValue.length === 1) {
        const filterToRemove: BinaryFilter = {
          member: filter.value,
          operator: 'equals',
          values: [...filterItem.values]
        };
        handleRemoveAudienceFilter(filterToRemove , audienceFilters, setAudienceFilters);
        const filterToAdd: BinaryFilter = {
          member: filter.value,
          operator: 'contains',
          values: [...new Set([...filterItem.values, ...option])]
        };
        handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
      } else {
        const filterToAdd: BinaryFilter = {
          member: filter.value,
          operator: 'contains',
          values: [...new Set([...filterItem.values, ...option])]
        };
        handleAddFilter(filterToAdd, audienceFilters, setAudienceFilters);
      }
      filterItem.values = [ ...new Set([ ...filterItem.values, ...option])];
      setFilterGroup([...filterGroup]);
      setFilterCheckedValue(prevValue => ([...new Set([...prevValue, ...option])]));
    } else {
      const previousValues = filterItem.values;
      const newValues = filterCheckedValue.filter((value: string | null) => value ? !option.includes(value) : false);
      setFilterCheckedValue([...newValues]);
      if (newValues.length === 0) {
        setFilterCheckedValue([]);
        const filterToRemove: BinaryFilter = {
          member: filter.value,
          operator: 'contains',
          values: previousValues
        };
        handleRemoveAudienceFilter(filterToRemove, audienceFilters, setAudienceFilters);
      } else {
        const filterToRemove: BinaryFilter = {
          member: filter.value,
          operator: 'contains',
          values: previousValues
        };
        handleRemoveFilterValue(filterToRemove, newValues, audienceFilters, setAudienceFilters);
      }
      filterItem.values = [...filterItem.values.filter((value: string) => value ? !option.includes(value) : false)];
      setFilterGroup([...filterGroup]);
      setFilterCheckedValue(newValues);
    }
    setFilterChanged(true);
    setTimeout(() => {
      setFilterChanged(false);
    }, 20);
  };

  const checkValue = (item: string): boolean => {
    const value = filterCheckedValue.find((value: string | null) => value === item);
    if (value || value === '' || value === null) {
      return true;
    } else {
      return false;
    }
  };

  const checkParentValue = (values: string[]): boolean => {
    return values.every((item: string) => filterCheckedValue.includes(item));
  };

  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent) => handleClose(event)} mouseEvent='onMouseUp'>
      <Popper
        placement='bottom-start'
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{ marginTop: '2px !important', zIndex: 30 }}
      >
        <Box className='checkbox-multi-select'>
          <Box
            sx={{ width: '100%', minWidth: '160px' }}
            className='u-p-12'
          >
            {searching ? (
              Array.from(Array(3).keys()).map((value: number) => (
                <Skeleton key={value + 1} variant='rectangular' height={30} className='u-mb-6' />
              ))
            ) : (
              filterOptions.map((item: { label: string, values: string[] }) => (
                <Box key={item.label}>
                  <FormControlLabel
                    className='checkbox-button'
                    checked={checkParentValue(item.values)}
                    control={
                      <Checkbox
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          parentFilterChanges(event.target.name as UnaryOperator, event.target.checked, event.target.value);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        classes={{
                          root: 'checkbox-root'
                        }}
                      />
                    }
                    classes={{
                      label: 'u-fw-700'
                    }}
                    label={item.label}
                    name={item.label}
                    value={item.label}
                  />
                  <Box className='u-flex u-flex-direction-column u-pl-20'>
                    {item.values.map((value: string) => (
                      item.label !== value &&
                      <FormControlLabel
                        key={value}
                        className='checkbox-button u-flex-100'
                        checked={checkValue(value)}
                        control={
                          <Checkbox
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              filterChanged(event.target.name as UnaryOperator, event.target.checked, value);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            classes={{
                              root: 'checkbox-root'
                            }}
                          />
                        }
                        label={value}
                        name={value}
                        value={value}
                      />
                    ))}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default PersonaFilter;
